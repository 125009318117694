import React, { Component } from 'react';
import { Progress } from 'reactstrap';
import { emptyDataMessage } from 'actions/common';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import styles from './AiStudioStyles.module.scss';
import { connect } from 'react-redux';
import actions from 'actions/AIStudio/ModelManagementActions';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import Loader from 'components/Loader/Loader';

export class ModelManagement extends Component {
  state = {
    trainModel: false,
    modelsList: [],
    trainingProgressData: {},
    progressMessage: {},
    messageEmpty: true
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.doFetchModels()).then(() => {
      this.setState({
        modelsList: this.props.modelsList
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modelsList !== this.props.modelsList) {
      this.setState({
        modelsList: this.props.modelsList
      });
    }

    if (prevProps.progressData !== this.props.progressData) {
      this.setState({
        trainingProgressData: this.props?.progressData
      });
    }
    if (prevProps.message !== this.props.message) {
      const isEmpty = Object.keys(this.props?.message)?.length === 0;
      this.setState({
        progressMessage: this.props.message,
        messageEmpty: isEmpty
      });
    }
  }

  handleCopyCategorizedEmail = () => {
    const { dispatch } = this.props;
    dispatch(actions.doCopyToSyntheticData());
  };

  handleTrainModel = () => {
    const { dispatch } = this.props;
    dispatch(actions.doTrainModel()).then(() => {
      this.setState({
        trainModel: true
      });
    });
  };

  handleSetActiveModel = (id) => {
    const { dispatch } = this.props;
    dispatch(actions.doSetActiveModel(id)).then(() => {
      dispatch(actions.doFetchModels());
    });
  };

  actionFormatter(cell, row) {
    return (
      <button
        type='button'
        className={styles.activeButton}
        disabled={row.activatedForDeployment}
        onClick={() => this.handleSetActiveModel(row?.id)}
      >
        {row.activatedForDeployment ? 'Active' : 'Make Active'}
      </button>
    );
  }
  render() {
    const columns = [
      {
        dataField: 'modelVersionName',
        text: 'Model Version'
      },
      {
        dataField: 'createdAt',
        text: 'Created On',
        formatter: dataFormat.dateTimeFormatter
      },
      {
        dataField: 'activatedForDeployment',
        text: 'Active',
        formatter: (cell, row) => {
          return <p>{cell ? 'Yes' : 'No'}</p>;
        }
      },
      {
        dataField: 'id',
        text: 'Actions',
        csvExport: false,
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (this.state.dropdownItem.includes(row.contentSearchId)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.id]
              });
            }
          }
        }
      }
    ];
    return (
      <div style={{ minHeight: '700px' }}>
        <div style={{ paddingBottom: '50px' }}>
          <p className={styles.modelHeader}>Populate Training Data</p>
          <button
            type='button'
            className={styles.secondaryButton}
            onClick={() => this.handleCopyCategorizedEmail()}
          >
            Copy Categorized Email to Training Data
          </button>
          {!this.state.messageEmpty && (
            <div className='ms-2 mt-2'>
              {`Copied ${this.state?.progressMessage?.count} labeled emails to training set in ${
                this.state?.progressMessage?.timeTakenMs / 1000
              }s.`}
            </div>
          )}
        </div>

        <div style={{ paddingBottom: '50px' }}>
          <p className={styles.modelHeader}>Trigger Model Training</p>
          <button
            type='button'
            className={styles.secondaryButton}
            onClick={() => this.handleTrainModel()}
          >
            Train Model
          </button>
        </div>
        {this.state.trainingProgressData?.progress > 0 && (
          <div style={{ paddingBottom: '50px' }}>
            <p className={styles.modelHeader}>Training Progress</p>
            <Progress
              value={this.state.trainingProgressData?.progress}
              className='progress-xs-wizard'
              style={{ height: '20px', background: ' #4A4A4A' }}
              barStyle={{ background: '#91C964', color: '#030303' }}
            >
              <span
                style={{
                  color: '#030303',
                  fontWeight: 'bold'
                }}
              >
                {this.state.trainingProgressData?.progress} %
              </span>
            </Progress>
            <p className='mt-2 mb-0'>
              Training Status: {this.state.trainingProgressData?.status} - Hyperparameter tuning
            </p>
            <p>
              Hyperparameter Tuning: {this.state.trainingProgressData?.progress}% - Best Score:{' '}
              {this.state.trainingProgressData?.bestScore}, Trials:{' '}
              {this.state.trainingProgressData?.trials}, ETA:
              {this.state.trainingProgressData?.etaInSeconds}s
            </p>
          </div>
        )}

        <div className='mt-3'>
          <p className={styles.modelHeader}>Trained Models</p>
          <ToolkitProvider bootstrap4 columns={columns} data={this.state.modelsList} keyField='id'>
            {(props) => (
              <div className={styles.tableContainer}>
                <BootstrapTable
                  bordered={false}
                  classes={`table-striped table-hover fs-sm custom-table`}
                  remote={{
                    filter: false,
                    pagination: false,
                    sort: false,
                    cellEdit: false
                  }}
                  noDataIndication={this.props?.loading ? <Loader /> : emptyDataMessage}
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    currentTheme: store.layout.currentTheme,
    modelsList: store.AIStudio.modelManagement.modelsList,
    loading: store.AIStudio.modelManagement.loading,
    loadingCopy: store.AIStudio.modelManagement.loadingCopy,
    message: store.AIStudio.modelManagement.message
  };
}

export default connect(mapStateToProps)(ModelManagement);
