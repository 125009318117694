import Errors from 'components/FormItems/error/errors';
import config from 'config';
import { defaultOptions } from 'services/userService';

async function getModels() {
  const response = await fetch(`${config.aiStudioApi}/api/ModelManagement/models`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function setActiveModel(id) {
  const response = await fetch(`${config.aiStudioApi}/api/ModelManagement/set-active-model/${id}`, {
    ...defaultOptions,
    method: 'POST'
  });
  const data = await response.json();
  return data;
}

async function trainModel() {
  const response = await fetch(`${config.aiStudioApi}/api/ModelManagement/train-model`, {
    ...defaultOptions,
    method: 'POST'
  });

  const data = await response.json();
  return data;
}

async function copyToSyntheticData() {
  const response = await fetch(`${config.aiStudioApi}/api/ModelManagement/copy-to-synthetic-data`, {
    ...defaultOptions,
    method: 'POST'
  });

  const data = await response.json();
  return data;
}

const actions = {
  doFetchModels: () => async (dispatch) => {
    try {
      dispatch({
        type: 'MODELS_LIST_FETCH_STARTED'
      });

      const response = await getModels();

      dispatch({
        type: 'MODELS_LIST_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'MODELS_LIST_FETCH_ERROR'
      });
    }
  },
  doSetActiveModel: (id) => async (dispatch) => {
    try {
      dispatch({
        type: 'ACTIVE_MODEL_SET_STARTED'
      });

      await setActiveModel(id);

      dispatch({
        type: 'ACTIVE_MODEL_SET_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ACTIVE_MODEL_SET_ERROR'
      });
    }
  },
  doTrainModel: () => async (dispatch) => {
    try {
      dispatch({
        type: 'TRAIN_MODEL_STARTED'
      });

      await trainModel();

      dispatch({
        type: 'TRAIN_MODEL_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'TRAIN_MODEL_ERROR'
      });
    }
  },
  doCopyToSyntheticData: () => async (dispatch) => {
    try {
      dispatch({
        type: 'COPY_TO_SYNTHETIC_DATA_STARTED'
      });

      const response = await copyToSyntheticData();

      dispatch({
        type: 'COPY_TO_SYNTHETIC_DATA_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'COPY_TO_SYNTHETIC_DATA_ERROR'
      });
    }
  }
};

export default { ...actions };
