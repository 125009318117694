import Errors from 'components/FormItems/error/errors';
import config from 'config';
import { defaultOptions } from 'services/userService';
import { toast } from 'react-toastify';

async function getConfiguration() {
  const response = await fetch(`${config.aiStudioApi}/api/Configuration`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function updateConfiguration(values) {
  const response = await fetch(`${config.aiStudioApi}/api/Configuration`, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(values)
  });

  const data = await response.json();
  return data;
}

async function importEmailExclusions(selectedFile) {
  const formData = new FormData();
  formData.append('file', selectedFile);

  const response = await fetch(`${config.aiStudioApi}/api/Configuration/import-email-exclusions`, {
    method: 'POST',
    body: formData
  });

  const data = await response.json();
  return data;
}

const actions = {
  doGetConfigurationData: () => async (dispatch) => {
    try {
      dispatch({
        type: 'CONFIGURATION_DATA_FETCH_STARTED'
      });

      const response = await getConfiguration();

      dispatch({
        type: 'CONFIGURATION_DATA_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CONFIGURATION_DATA_FETCH_ERROR'
      });
    }
  },
  doUpdateConfigurationData: (data) => async (dispatch) => {
    try {
      dispatch({
        type: 'CONFIGURATION_DATA_UPDATE_STARTED'
      });

      await updateConfiguration(data);

      dispatch({
        type: 'CONFIGURATION_DATA_UPDATE_SUCCESS'
      });
      toast.success('Configuration updated successfully.', { icon: false });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CONFIGURATION_DATA_UPDATE_ERROR'
      });
    }
  },
  doImportEmailExclusions: (data) => async (dispatch) => {
    try {
      dispatch({
        type: 'IMPORT_EMAIL_EXCLUSIONS_STARTED'
      });

      await importEmailExclusions(data);

      dispatch({
        type: 'IMPORT_EMAIL_EXCLUSIONS_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'IMPORT_EMAIL_EXCLUSIONS_ERROR'
      });
    }
  }
};

export default { ...actions };
