import Errors from 'components/FormItems/error/errors';
import config from 'config';
import { defaultOptions } from 'services/userService';

async function getEmails(
  sortOrder,
  pageNumber,
  pageSize,
  searchString,
  hasAttachments,
  categoryType
) {
  let baseUrl = `${config.aiStudioApi}/api/EmailLabeling/emails?`;
  if (pageNumber !== undefined) {
    baseUrl += 'page=' + encodeURIComponent('' + pageNumber) + '&';
  }
  if (pageSize !== undefined) {
    baseUrl += 'pageSize=' + encodeURIComponent('' + pageSize) + '&';
  }
  if (searchString !== undefined && searchString !== '') {
    baseUrl += 'searchString=' + encodeURIComponent('' + searchString) + '&';
  }
  if (sortOrder !== undefined && sortOrder !== '') {
    baseUrl += 'sortOrder=' + encodeURIComponent('' + sortOrder) + '&';
  }
  if (hasAttachments !== undefined && hasAttachments !== '') {
    baseUrl += 'hasAttachments=' + encodeURIComponent('' + hasAttachments) + '&';
  }
  if (categoryType !== undefined && categoryType !== null) {
    baseUrl += `categoryType=` + encodeURIComponent('' + categoryType) + '&';
  }

  baseUrl = baseUrl.replace(/[?&]$/, '');
  const response = await fetch(baseUrl, { ...defaultOptions, method: 'GET' });

  const data = await response.json();
  return data;
}

async function startLlmCategorization(values) {
  const response = await fetch(
    `${config.aiStudioApi}/api/EmailLabeling/kickoff-llm-categorization`,
    {
      ...defaultOptions,
      method: 'POST',
      body: JSON.stringify(values)
    }
  );
  const data = await response.json();
  return data;
}

async function updateCategory(values) {
  const response = await fetch(`${config.aiStudioApi}/api/EmailLabeling/update-category`, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(values)
  });

  const data = await response.json();
  return data;
}

async function deleteEmails(values) {
  const response = await fetch(`${config.aiStudioApi}/api/EmailLabeling/delete-emails`, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify(values)
  });
  const data = await response.json();
  return data;
}

const actions = {
  doFetchEmails: (filter) => async (dispatch) => {
    try {
      dispatch({
        type: 'EMAIL_LIST_FETCH_STARTED'
      });

      const response = await getEmails(
        filter.sortOrder,
        filter.pageNumber,
        filter.pageSize,
        filter.searchString,
        filter.hasAttachments,
        filter.categoryType
      );

      dispatch({
        type: 'EMAIL_LIST_FETCH_SUCCESS',
        payload: {
          notLabeledEmails: response.notLabeledEmails,
          labeledEmails: response.labeledEmails,
          notLabeledCount: response.notLabeledCount,
          labeledCount: response.labeledCount
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EMAIL_LIST_FETCH_ERROR'
      });
    }
  },
  doStartLLMCategorization: (data) => async (dispatch) => {
    try {
      dispatch({
        type: 'LLM_CATEGORIZATION_STARTED'
      });

      await startLlmCategorization(data);

      dispatch({
        type: 'LLM_CATEGORIZATION_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LLM_CATEGORIZATION_ERROR'
      });
    }
  },
  doUpdateCategory: (data) => async (dispatch) => {
    try {
      dispatch({
        type: 'CATEGORY_UPDATE_STARTED'
      });

      await updateCategory(data);

      dispatch({
        type: 'CATEGORY_UPDATE_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CATEGORY_UPDATE_ERROR'
      });
    }
  },
  doDeleteEmails: (data) => async (dispatch) => {
    try {
      dispatch({
        type: 'DELETE_EMAILS_STARTED'
      });

      await deleteEmails(data);

      dispatch({
        type: 'DELETE_EMAILS_SUCCESS'
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'DELETE_EMAILS_ERROR'
      });
    }
  },
  doOpenDetailsModal: () => async (dispatch) => {
    dispatch({
      type: 'EMAIL_DETAILS_MODAL_OPEN'
    });
  },
  doCloseDetailsModal: () => async (dispatch) => {
    dispatch({
      type: 'EMAIL_DETAILS_MODAL_CLOSE'
    });
  }
};

export default { ...actions };
