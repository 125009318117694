import React, { Component } from 'react';
import SearchListTable from 'components/CRUD/Search/list/SearchListTable';
import SearchTable from './SearchTable';
import Widget from 'components/Widget/Widget';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import actions from 'actions/search/searchListActions';
import config from 'config';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import s from 'components/CRUD/Search/itemContent/styles.module.scss';
import FileSearchListTable from '../fileContentSearch/FileSearchListTable';
import FileSearchTiles from '../fileContentSearch/FileSearchTiles';

class SearchListPage extends Component {
  state = {
    defaultTabsTabId: '1'
  };

  componentDidMount() {
    const { dispatch } = this.props;
    if (this.props?.caseStatus === config.caseStatus.Closed) {
      dispatch(actions.doShowTable());
    } else {
      dispatch(actions.doHideTable());
    }
  }

  changeTab(field, id) {
    this.setState({
      [field]: id
    });
  }

  render() {
    return (
      <div>
        <Widget style={{ marginTop: '20px' }}>
          <Row>
            <Col style={{ marginTop: '0px', marginBottom: '10px' }}>
              <div className={'d-flex align-items-center'}>
                <h3 className={'first-headline-text me-4 mb-0'}>Content Searches</h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Nav tabs className='bg-transparent d-flex mt'>
              <NavItem
                className={this.state.defaultTabsTabId === '1' ? s.navItem : s.linkText}
                style={{ marginLeft: '8px' }}
              >
                <NavLink
                  className={this.state.defaultTabsTabId === '1' ? s.navLink : s.linkText}
                  onClick={() => {
                    this.changeTab('defaultTabsTabId', '1');
                  }}
                >
                  Mail
                </NavLink>
              </NavItem>
              <NavItem className={this.state.defaultTabsTabId === '2' ? s.navItem : s.linkText}>
                <NavLink
                  className={this.state.defaultTabsTabId === '2' ? s.navLink : s.linkText}
                  onClick={() => {
                    this.changeTab('defaultTabsTabId', '2');
                  }}
                >
                  File
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className='mb-xlg' activeTab={this.state.defaultTabsTabId}>
              {this.state.defaultTabsTabId === '1' && (
                <TabPane className={s.tabPane} tabId='1'>
                  <>
                    {this.props.showTableView ? (
                      <SearchTable
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    ) : (
                      <SearchListTable
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    )}
                  </>
                </TabPane>
              )}
              {this.state.defaultTabsTabId === '2' && (
                <TabPane className={s.tabPane} tabId='2'>
                  <>
                    {this.props.showTableView ? (
                      <FileSearchListTable
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    ) : (
                      <FileSearchTiles
                        legalCaseId={this.props.legalCaseId}
                        caseStatus={this.props.caseStatus}
                        navigate={this.props.navigate}
                      />
                    )}
                  </>
                </TabPane>
              )}
            </TabContent>
          </Row>
        </Widget>
      </div>
    );
  }
}
function mapStateToProps(store) {
  return {
    showTableView: store.search.list.showTableView
  };
}
export default withRouter(connect(mapStateToProps)(SearchListPage));
