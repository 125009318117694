const initialData = {
  filesList: [],
  directoriesList: [],
  rows: [],
  count: 0,
  totalCount: 0,
  fileDetails: {},
  loadingPreview: false,
  loading: false
};

export default (state = initialData, { type, payload }) => {
  if (type === 'LEGALHOLD_DIRECTORIES_LIST_FETCH_STARTED') {
    return {
      ...state
    };
  }
  if (type === 'LEGALHOLD_DIRECTORIES_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      directoriesList: payload.directoriesList
    };
  }
  if (type === 'LEGALHOLD_DIRECTORIES_LIST_FETCH_ERROR') {
    return {
      ...state,
      directoriesList: []
    };
  }
  if (type === 'LEGALHOLD_FILES_LIST_FETCH_STARTED') {
    return {
      ...state
    };
  }
  if (type === 'LEGALHOLD_FILES_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      filesList: payload.filesList,
      totalCount: payload.totalCount
    };
  }
  if (type === 'LEGALHOLD_FILES_LIST_FETCH_ERROR') {
    return {
      ...state,
      filesList: [],
      totalCount: 0
    };
  }
  if (type === 'FILE_LEGALHOLD_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }
  if (type === 'FILE_LEGALHOLD_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count
    };
  }
  if (type === 'FILE_LEGALHOLD_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0
    };
  }
  if (type === 'LEGALHOLD_FILE_PREVIEW_FETCH_STARTED') {
    return {
      ...state,
      loadingPreview: true
    };
  }
  if (type === 'LEGALHOLD_FILE_PREVIEW_FETCH_SUCCESS') {
    return {
      ...state,
      fileDetails: payload,
      loadingPreview: false
    };
  }
  if (type === 'LEGALHOLD_FILE_PREVIEW_FETCH_ERROR') {
    return {
      ...state,
      fileDetails: {},
      loadingPreview: false
    };
  }
  return state;
};
