import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import ApexChart from 'react-apexcharts';
import chartBar from 'images/icons/aiStudio/chartBar.svg';
import styles from './AiStudioStyles.module.scss';
import serverIcon from 'images/icons/aiStudio/server.svg';
import bulletIcon from 'images/icons/aiStudio/Bullet.svg';
import { connect } from 'react-redux';
import actions from 'actions/AIStudio/DashboardActions';
import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import { FormattingService } from 'utils/sizeFormatting';
import { activeSamplingEnum } from 'actions/common';
import configurationActions from 'actions/AIStudio/ConfigurationActions';

export class AIDashboard extends Component {
  state = {
    categoryDistribution: [],
    trainingCategoryDistribution: [],
    trainingSyntheticDistribution: [],
    emailsToBeLabeledCount: 0,
    labeledEmailsCount: 0,
    inferenceMetrics: [],
    trainedModelHistory: [],
    activeTrainedModel: {},
    inferenceMetricsChart: {},
    modelStats: {},
    averageLatency: 0,
    averageConfidence: 0,
    selectedDuration: 'FiveMinute',
    emailSettings: {}
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.doGetCategoryDistribution());
    dispatch(actions.doGetTrainingCategoryDistribution());
    dispatch(actions.doGetTrainingSyntheticDistribution());
    dispatch(actions.doGetEmailsToBeLabeledCount());
    dispatch(actions.doGetLabeledEmailsCount());
    dispatch(actions.doGetInferenceMetrics(this.state.selectedDuration));
    dispatch(actions.doGetActiveTrainedModel());
    dispatch(actions.doGetTrainedModelHistory());
    dispatch(actions.doGetEmailLabelingSettings());
    dispatch(configurationActions.doGetConfigurationData());
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps !== this.props) {
      this.setState({
        categoryDistribution: this.formatChartData(this.props.categoryDistribution),
        trainingCategoryDistribution: this.formatChartData(
          this.props?.trainingCategoryDistribution
        ),
        trainingSyntheticDistribution: this.formatChartData(
          this.props?.trainingSyntheticDistribution
        ),
        emailsToBeLabeledCount: this.props.emailsToBeLabeledCount,
        labeledEmailsCount: this.props.labeledEmailsCount,
        inferenceMetrics: this.props?.inferenceMetrics,
        trainedModelHistory: this.props.trainedModelHistory,
        activeTrainedModel: this.props.activeTrainedModel,
        inferenceMetricsChart:
          this.props?.inferenceMetrics.length !== 0
            ? this.formattedInferenceMetrics(this.props?.inferenceMetrics[0])
            : {},
        modelStats:
          this.props.activeTrainedModel?.modelStatisticsJson !== ''
            ? this.formatModalStats(this.props.activeTrainedModel?.modelStatisticsJson)
            : {},
        averageConfidence: this.props?.inferenceMetrics[0]?.averageConfidence,
        averageLatency: this.props?.inferenceMetrics[0]?.averageLatencyMs,
        emailSettings: this.props?.emailLabelingSettings
      });
    }
    if (prevState.selectedDuration !== this.state.selectedDuration) {
      dispatch(actions.doGetInferenceMetrics(this.state.selectedDuration));
    }
  }

  formatChartData(data) {
    const categories = [];
    const count = [];
    data?.map((item) => {
      const formattedCategory = `${item.category}: ${item.count} [${item.percentage}%]`;
      categories.push(formattedCategory);
      count.push(item.count);
    });
    const formattedData = { categories, count };
    return formattedData;
  }

  formattedInferenceMetrics(data) {
    const categoryCounts = data?.categoryCounts;
    const categories = [];
    const count = [];
    Object.entries(categoryCounts).map((item) => {
      const formattedCategory = `${item[1]?.category}: ${item[1]?.count} [${item[1]?.percentage}%]`;
      categories.push(formattedCategory);
      count.push(item[1]?.count);
    });
    const formattedData = { categories, count };
    return formattedData;
  }

  formatModalStats(data) {
    const statsObj = data ? JSON.parse(data) : {};
    return statsObj;
  }

  render() {
    const toReadableSizeFormat = new FormattingService();
    const durationOptions = [
      { label: 'Last 5 minutes', value: 'FiveMinute' },
      { label: 'Hourly', value: 'Hourly' },
      { label: 'Daily', value: 'Daily' },
      { label: 'Weekly', value: 'Weekly' },
      { label: 'Monthly', value: 'Monthly' }
    ];

    return (
      <div style={{ minHeight: '700px' }}>
        <div className='d-flex justify-content-between align-content-center w-100'>
          <Widget className='w-50 me-4'>
            <div className={styles.container}>
              <div className='text-center'>
                <p className={styles.statsLabel}>Unlabeled Emails</p>
                <p className={styles.statsValue}>
                  {toReadableSizeFormat.numberWithCommas(this.state.emailsToBeLabeledCount)}
                </p>
              </div>
              <div className='text-center'>
                <p className={styles.statsLabel}>Labeled Emails</p>
                <p className={styles.statsValue}>
                  {toReadableSizeFormat.numberWithCommas(this.state.labeledEmailsCount)}
                </p>
              </div>
            </div>
          </Widget>
          <Widget className='w-50'>
            <div className={styles.container}>
              <div className='text-center'>
                <p className={styles.statsLabel}>Average Inference Time</p>
                <p className={styles.statsValue}>
                  {parseFloat(this.state.averageLatency?.toFixed(2))}ms
                </p>
              </div>
              <div className='text-center'>
                <p className={styles.statsLabel}>Average Confidence</p>
                <p className={styles.statsValue}>
                  {(this.state.averageConfidence * 100).toFixed(2)}%
                </p>
              </div>
            </div>
          </Widget>
        </div>
        <div className='d-flex w-100'>
          <div className='w-50 me-4'>
            <Widget style={{ height: '220px' }}>
              <p className={styles.pieHeader}>Manually Labeled Data</p>
              <div>
                <ApexChart
                  type='donut'
                  style={{ color: 'white' }}
                  series={
                    this.state.categoryDistribution.count
                      ? this.state.categoryDistribution.count
                      : []
                  }
                  height={150}
                  options={{
                    dataLabels: {
                      enabled: false
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '50%',
                          labels: {
                            show: false
                          }
                        }
                      }
                    },
                    colors: ['#94D13D', '#6F58E9', '#2D99FE', '#2DDAC1', '#77FF60'],
                    labels: this.state.categoryDistribution.categories
                      ? this.state.categoryDistribution.categories
                      : [],
                    legend: {
                      color: 'white'
                    },
                    fill: {
                      colors: ['#94D13D', '#6F58E9', '#2D99FE', '#2DDAC1', '#77FF60']
                    },
                    stroke: {
                      width: 0
                    },
                    chart: {
                      foreColor: '#fff',
                      fontFamily: 'Poppins'
                    }
                  }}
                />
              </div>
            </Widget>
            <Widget style={{ height: '220px' }}>
              <p className={styles.pieHeader}>Trainingset Distribution</p>
              <div>
                <ApexChart
                  type='donut'
                  style={{ color: 'white' }}
                  series={
                    this.state.trainingCategoryDistribution.count
                      ? this.state.trainingCategoryDistribution.count
                      : []
                  }
                  height={150}
                  options={{
                    dataLabels: {
                      enabled: false
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '50%',
                          labels: {
                            show: false
                          }
                        }
                      }
                    },
                    colors: ['#94D13D', '#6F58E9', '#2D99FE', '#2DDAC1', '#77FF60'],
                    labels: this.state.trainingCategoryDistribution?.categories
                      ? this.state.trainingCategoryDistribution?.categories
                      : [],
                    legend: {
                      color: 'white'
                    },
                    fill: {
                      colors: ['#94D13D', '#6F58E9', '#2D99FE', '#2DDAC1', '#77FF60']
                    },
                    stroke: {
                      width: 0
                    },
                    chart: {
                      foreColor: '#fff',
                      fontFamily: 'Poppins'
                    }
                  }}
                />
              </div>
            </Widget>
            <Widget style={{ height: '220px' }}>
              <p className={styles.pieHeader}>Trainingset Synthetic Data</p>
              <div style={{ marginRight: '10%' }}>
                <ApexChart
                  type='donut'
                  style={{ color: 'white' }}
                  series={
                    this.state.trainingSyntheticDistribution.count
                      ? this.state.trainingSyntheticDistribution.count
                      : []
                  }
                  height={150}
                  options={{
                    dataLabels: {
                      enabled: false
                    },
                    plotOptions: {
                      pie: {
                        donut: {
                          size: '50%',
                          labels: {
                            show: false
                          }
                        }
                      }
                    },
                    colors: ['#94D13D', '#6F58E9', '#2D99FE', '#2DDAC1', '#77FF60'],
                    labels: this.state.trainingSyntheticDistribution.categories
                      ? this.state.trainingSyntheticDistribution.categories
                      : [],
                    legend: {
                      color: 'white'
                    },
                    fill: {
                      colors: ['#94D13D', '#6F58E9', '#2D99FE', '#2DDAC1', '#77FF60']
                    },
                    stroke: {
                      width: 0
                    },
                    chart: {
                      foreColor: '#fff',
                      fontFamily: 'Poppins'
                    }
                  }}
                />
              </div>
            </Widget>

            <Widget style={{ height: '220px' }}>
              <p className={styles.pieHeader}>Email Labeling Settings</p>
              <p className='d-flex justify-content-between align-content-center'>
                <span className={styles.statsLabel}>Sampling</span>
                <span className={styles.statsLabel}>
                  {this.state.emailSettings?.activeSamplingConfiguration !==
                  activeSamplingEnum.NoSampling
                    ? 'On'
                    : 'Off'}
                </span>
              </p>
              <p className='d-flex justify-content-between align-content-center'>
                <span className={styles.statsLabel}>LLM auto-mapping</span>
                <span className={styles.statsLabel}>
                  {this.state.emailSettings?.autoMapLLMMatchingSuggestions ? 'On' : 'Off'}
                </span>
              </p>
              <p className='d-flex justify-content-between align-content-center'>
                <span className={styles.statsLabel}>Email addresses excluded from sampling</span>
                <span className={styles.statsLabel}>{this.state.emailSettings?.count}</span>
              </p>
            </Widget>
          </div>
          <div className='w-50'>
            <Widget style={{ height: '600px' }}>
              <div className='d-flex'>
                <img src={chartBar} alt={'chart'} className={'me-2'} width='48px' height='48px' />
                <p className={styles.pieHeader}>Current Inference Metrics</p>
              </div>
              <p className='d-flex justify-content-start align-items-center'>
                <p className={`${styles.statsLabel}  mb-0 `}>Select Duration:</p>
                <select
                  value={this.state.selectedDuration}
                  onChange={(e) => {
                    this.setState({
                      selectedDuration: e.target.value
                    });
                  }}
                  className={`${styles.secondaryButton} pe-2`}
                >
                  {durationOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </p>
              <div
                className={`d-flex justify-content-between align-content-center ${styles.statsLabel}`}
              >
                <p>Total Requests</p>
                <p>{this.state.inferenceMetrics[0]?.totalRequests}</p>
              </div>
              <hr className={styles.divider} />
              <ApexChart
                type='donut'
                style={{ color: 'white' }}
                series={
                  this.state.inferenceMetricsChart.count
                    ? this.state.inferenceMetricsChart.count
                    : []
                }
                height={150}
                options={{
                  dataLabels: {
                    enabled: false
                  },
                  plotOptions: {
                    pie: {
                      donut: {
                        size: '50%',
                        labels: {
                          show: false
                        }
                      }
                    }
                  },
                  colors: ['#94D13D', '#6F58E9', '#2D99FE', '#2DDAC1', '#77FF60'],
                  labels: this.state.inferenceMetricsChart.categories
                    ? this.state.inferenceMetricsChart.categories
                    : [],
                  legend: {
                    color: 'white'
                  },
                  fill: {
                    colors: ['#94D13D', '#6F58E9', '#2D99FE', '#2DDAC1', '#77FF60']
                  },
                  stroke: {
                    width: 0
                  },
                  chart: {
                    foreColor: '#fff',
                    fontFamily: 'Poppins'
                  }
                }}
              />
              <hr className={styles.divider} />
              <div
                className={`d-flex justify-content-between align-content-center ${styles.statsLabel}`}
              >
                <p>Error Requests</p>
                <p>{this.state.inferenceMetrics[0]?.errorRequests}</p>
              </div>
            </Widget>
            <Widget>
              <div className='d-flex'>
                <img src={serverIcon} alt={'chart'} className={'me-2'} width='48px' height='48px' />
                <p className={styles.pieHeader}>Active Deployed Model</p>
              </div>
              <p className={`${styles.statsLabel} mt-2`}>
                Version: {this.state.activeTrainedModel?.modelVersionName}
              </p>
              <p className={styles.statsLabel}>
                Created On: {dataFormat.dateTimeFormatter(this.state.activeTrainedModel?.createdAt)}
              </p>
              <hr className={styles.divider} />
              <p className={styles.statsLabel}>Model Statistics</p>
              {this.props?.activeTrainedModel?.modelStatisticsJson !== '' && (
                <>
                  {Object.entries(this.state.modelStats).map((item) => {
                    return (
                      <p className='ms-4'>
                        <img
                          src={bulletIcon}
                          alt={'bullet'}
                          className={'me-2'}
                          width='16px'
                          height='16px'
                        />
                        <span>
                          {item[0]}: {parseFloat(item[1].toFixed(4))}
                        </span>
                      </p>
                    );
                  })}
                </>
              )}
            </Widget>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    categoryDistribution: store.AIStudio.dashboard.categoryDistribution,
    trainingCategoryDistribution: store.AIStudio.dashboard.trainingCategoryDistribution,
    emailsToBeLabeledCount: store.AIStudio.dashboard.emailsToBeLabeledCount,
    labeledEmailsCount: store.AIStudio.dashboard.labeledEmailsCount,
    trainingSyntheticDistribution: store.AIStudio.dashboard.trainingSyntheticDistribution,
    inferenceMetrics: store.AIStudio.dashboard.inferenceMetrics,
    trainedModelHistory: store.AIStudio.dashboard.trainedModelHistory,
    activeTrainedModel: store.AIStudio.dashboard.activeTrainedModel,
    emailLabelingSettings: store.AIStudio.dashboard.emailLabelingSettings
  };
}

export default connect(mapStateToProps)(AIDashboard);
