import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import actions from 'actions/search/searchListActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col
} from 'reactstrap';
import Widget from 'components/Widget';
import plusIcon from '../../../../images/icons/plus.svg';
import viewIcon from '../../../../images/icons/eye.svg';
import SearchFormPage from '../form/SearchFormPage';
import { FormattingService } from '../../../../utils/sizeFormatting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import Pagination from 'react-js-pagination';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import SearchListTooltip from 'components/CRUD/Search/list/SearchListTooltip';
import exclamationMark from 'images/icons/exclamationMark.svg';
import LegalHoldFormTooltip from 'components/CRUD/LegalHold/form/LegalHoldFormTooltip';
import searchStyles from 'components/CRUD/Search/list/SearchTable.module.scss';
import formActions from 'actions/search/searchFormActions';
import searchIcon from 'images/icons/search.svg';
import { VscOpenPreview } from 'react-icons/vsc';
import caretDown from 'images/icons/caret-down.svg';
import { customCloseBtn, contentSearchTypeEnum } from 'actions/common';
import config from 'config';

class SearchListTable extends Component {
  intervalID = 0;
  state = {
    builderVisible: false,
    rows: [],
    pageNumber: 1,
    searchString: '',
    pageSize: 3,
    totalCount: 0,
    paginationModalOpen: false,
    position: {
      top: 0,
      left: 0
    },
    content: '',
    showContent: false,
    cardPosition: {
      top: 0,
      left: 0
    }
  };

  openModal() {
    const { dispatch } = this.props;
    dispatch(actions.doOpenConfirm());
  }

  closeModal() {
    const { dispatch } = this.props;
    dispatch(actions.doCloseConfirm());
    dispatch(formActions.doToggleBuilder(false));
    if (this.props.isDiscarded) {
      localStorage.removeItem(`${this.props.contentSearch?.name}-${this.props.legalCaseId}`);
    }
  }

  showTable() {
    const { dispatch } = this.props;
    dispatch(actions.doShowTable());
  }

  actionFormatter(cell, cell2) {
    const { caseStatus } = this.props;
    const isNotActive =
      caseStatus === config.caseStatus.Closed ||
      caseStatus === config.caseStatus.LegalHoldReleased ||
      caseStatus === config.caseStatus.Frozen;
    return (
      <div className='d-flex'>
        {this.props.permissions['LegalUI.ContentSearches'] ? (
          <>
            <div>
              {!isNotActive && (
                <button
                  className='btn first-body-text d-flex align-items-center'
                  type='button'
                  onClick={() => {
                    this.props.navigate(`/app/search/${cell}/${cell2}`);
                  }}
                >
                  <VscOpenPreview
                    style={{
                      fontWeight: 'bold',
                      width: '16px',
                      height: '16px'
                    }}
                  />
                  <p className={'mb-0 ms-2'}>Preview</p>
                </button>
              )}
            </div>
            <div>
              <button
                className='btn first-body-text d-flex align-items-center ms-3'
                type='button'
                onClick={() => {
                  this.props.navigate(`/app/search/${cell}`);
                }}
              >
                <img src={viewIcon} alt={'view'} />
                <p className={'mb-0 ms-2'}>Details</p>
              </button>
            </div>
          </>
        ) : null}
      </div>
    );
  }

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doFetch({
        legalCaseId: this.props.legalCaseId,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        contentSearchType: contentSearchTypeEnum.Mail
      })
    ).then(() => {
      this.setState({
        rows: this.props.rows,
        totalCount: this.props.count
      });
    });
  };

  refreshTable = () => {
    const { dispatch } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(
        actions.doFetch({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          contentSearchType: contentSearchTypeEnum.Mail
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          rows: this.props.rows
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      actions.doFetch({
        legalCaseId: this.props.legalCaseId,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        contentSearchType: contentSearchTypeEnum.Mail
      })
    ).then(() => {
      this.setState({
        rows: this.props.rows,
        totalCount: this.props.count
      });
    });
    if (this.props.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch } = this.props;
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        rows: this.props.rows
      });
    }
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber
    ) {
      dispatch(
        actions.doFetch({
          legalCaseId: this.props.legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          contentSearchType: contentSearchTypeEnum.Mail
        })
      ).then(() => {
        this.setState({
          rows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    window.removeEventListener('click', (e) => this.resetDropdowns(e));
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleSearchInDisplay = (item) => {
    let array = [];
    if (item?.searchInHeader) {
      array.push('Header');
    }
    if (item?.searchInSubject) {
      array.push('Subject');
    }
    if (item?.searchInBody) {
      array.push('Body');
    }
    if (item?.searchInAttachment) {
      array.push('Attachment');
    }
    return array.join(', ');
  };

  render() {
    const toReadableSizeFormat = new FormattingService();
    return (
      <div>
        <Row key={'table-part'} style={{ marginBottom: '20px' }}>
          <Col style={{ marginTop: '8px' }}>
            <div className={'d-flex align-items-center'}>
              {this.props.permissions['LegalUI.ContentSearches.Create'] &&
              this.props?.caseStatus !== config.caseStatus.Closed ? (
                <div>
                  <button
                    className='btn first-body-text d-flex align-items-center'
                    type='button'
                    onClick={() => {
                      this.openModal();
                    }}
                  >
                    <img src={plusIcon} alt={'plus'} className={'me-2'} />
                    <p className={'mb-0'}>New mail</p>
                  </button>
                </div>
              ) : null}
            </div>
          </Col>
          <Col
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '5px'
            }}
          >
            <button
              className='btn fw-semi-bold d-flex align-items-center'
              type='button'
              onClick={() => this.showTable()}
              style={{ height: '37px', marginRight: '5px' }}
            >
              <FontAwesomeIcon icon={faTable} />
            </button>
            <span>
              <input
                type='search'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.handleSearch();
                  }
                }}
                placeholder={'Search'}
                style={{ border: '0.5px solid #0046b1', borderRadius: '3px' }}
                className={'form-control search-input me-5 w-200'}
                value={this.state.searchString}
                onChange={(e) => {
                  this.setState({
                    searchString: e.target.value
                  });
                }}
              />
            </span>
            <span>
              <button
                className='btn search-button-with-icon me-2 ms-10'
                type='button'
                onClick={this.handleSearch}
                style={{ height: '37px', width: '37px' }}
              >
                <span>
                  <img title={'search'} alt={'search'} width={14} height={14} src={searchIcon} />
                </span>
              </button>
            </span>
          </Col>
        </Row>
        <Row>
          {this.state.rows?.length ? (
            this.state.rows?.map((item) => (
              <Col sm={4} key={item.contentSearchId}>
                <Widget style={{ height: '400px', overflow: 'auto' }}>
                  {this.state.showContent && (
                    <SearchListTooltip
                      position={this.state.position}
                      content={this.state.content}
                    />
                  )}
                  <div className={'d-flex justify-content-between align-items-center mb-3'}>
                    <div className={`${searchStyles.cardName} first-headline-text`}>
                      {item.name}
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>
                        {item?.itemsStoreFailed && (
                          <img
                            className={searchStyles.exclamationMark}
                            src={exclamationMark}
                            alt={'exclamation-mark'}
                            onMouseOver={(e) => {
                              this.setState({
                                contentSearchId: item?.contentSearchId,
                                content: {
                                  value:
                                    'This content search is malformed. Please re-create this content search.'
                                },
                                cardPosition: {
                                  top: e.pageY,
                                  left: e.pageX
                                }
                              });
                            }}
                            onMouseLeave={() => {
                              this.setState({
                                contentSearchId: null,
                                content: null,
                                cardPosition: {
                                  top: 0,
                                  left: 0
                                }
                              });
                            }}
                          />
                        )}
                      </div>
                      {this.state.contentSearchId === item?.contentSearchId && (
                        <LegalHoldFormTooltip
                          currentCustodian={this.state.content}
                          position={this.state.cardPosition}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <div>
                      <p className='mb-0'>
                        <span className={'me-2 first-subtitle-text'}>Query:</span>
                        <span
                          onMouseOver={(e) => {
                            this.setState({
                              position: {
                                top: e.pageY,
                                left: e.pageX
                              },
                              content: item?.queryString?.query,
                              showContent: item.queryString.query?.length > 30 ? true : false
                            });
                          }}
                          onMouseLeave={() =>
                            this.setState({
                              position: {
                                top: 0,
                                left: 0
                              },
                              content: '',
                              showContent: false
                            })
                          }
                          className='first-body-text'
                        >
                          {item?.queryString?.query.length > 30
                            ? item?.queryString?.query.slice(0, 30) + '...'
                            : item?.queryString?.query}
                        </span>
                      </p>
                      <p className='mb-0' style={{ maxWidth: '350px' }}>
                        <span className='me-2 first-subtitle-text'>in:</span>
                        <span className='first-body-text'>{this.handleSearchInDisplay(item)}</span>
                        <span className={'first-body-text me-2'}> ({item?.fuzzynessType})</span>
                      </p>
                      <p className='mb-0'>
                        <span className={'me-2 first-subtitle-text'}>across:</span>
                        <span className='first-body-text'>Email</span>
                        {dataFormat.booleanFormatter(item.onlyMailsWithAttachments) === 'Yes' ? (
                          <span className='first-body-text'> (with attachments only)</span>
                        ) : null}
                      </p>
                      <p>
                        <span className={'me-2 first-subtitle-text'}>Legal Holds:</span>
                        <span className='first-body-text'>{item.legalHoldNames.join(', ')}</span>
                      </p>
                    </div>
                    <div>
                      <p className={`${styles.sectionHeader} first-subtitle-text`}>Results</p>
                      <p className={'mb-0'}>
                        <span className='first-subtitle-text me-2'>Items:</span>
                        <span className='me-2 first-body-text'>
                          {toReadableSizeFormat.numberWithCommas(item.totalItems)}
                        </span>
                        <span className='m-2'>-</span>
                        <span className='first-body-text'>
                          {toReadableSizeFormat.numberWithCommas(item?.hasAttachmentsCount || 0)}
                          <span className='ms-1'> Attachments</span>
                        </span>
                      </p>
                      <p>
                        <span className='me-2 first-subtitle-text'>Size :</span>
                        <span className='me-2 first-body-text'>
                          {toReadableSizeFormat.getBytesReadable(item.totalSize)}
                        </span>
                      </p>
                    </div>
                    <div>
                      <span className='first-subtitle-text me-2'>Created by:</span>
                      <span className='me-1 first-body-text'>{item?.expireonUser?.firstName}</span>
                      <span className='me-1 first-body-text'>{item?.expireonUser?.lastName}</span>
                      <span className='first-body-text'>
                        ({dataFormat.dateTimeFormatter(item?.creationDate)})
                      </span>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end mt-5'>
                    {this.actionFormatter(item.contentSearchId, this.props.legalCaseId)}
                  </div>
                </Widget>
              </Col>
            ))
          ) : (
            <div
              style={{ height: this.state.rows?.length ? 500 : 'auto' }}
              className={'d-flex w-100 justify-content-center align-items-center'}
            >
              <p className={'text-white'}>No data available</p>
            </div>
          )}
        </Row>
        <Row key={'pagination'}>
          <Col style={{ marginTop: '3px' }}>
            <p>Total: {this.state.totalCount}</p>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {this.state.totalCount ? (
              <Pagination
                totalItemsCount={this.state.totalCount}
                onChange={this.handlePageChange}
                activePage={this.state.pageNumber}
                itemsCountPerPage={this.state.pageSize}
                pageRangeDisplayed={5}
                prevPageText={'<'}
                nextPageText={'>'}
                firstPageText={'<<'}
                lastPageText={'>>'}
                linkClassFirst={styles.paginationNext}
                linkClassPrev={styles.paginationNext}
                linkClassNext={styles.paginationNext}
                linkClassLast={styles.paginationNext}
                linkClass={styles.pageLink}
                activeLinkClass={styles.activeLinkClass}
              />
            ) : null}
            <Dropdown
              isOpen={this.state.paginationModalOpen}
              toggle={() =>
                this.setState({
                  paginationModalOpen: !this.state.paginationModalOpen
                })
              }
              className={styles.pageSizeDropdown}
            >
              <DropdownToggle className='bg-transparent text-white d-flex'>
                <p style={{ marginBottom: '3px', marginRight: '2px' }} className='first-body-text'>
                  {this.state.pageSize}
                </p>
                <img
                  src={caretDown}
                  alt={'uncollapsed'}
                  width='20px'
                  height='20px'
                  className='ms-2'
                />
              </DropdownToggle>
              <DropdownMenu className='dropdown-position-fixed'>
                {[3, 6, 9].map((item) => (
                  <DropdownItem
                    className={styles.dropdownItem}
                    key={item}
                    onClick={() => {
                      this.setState({
                        pageSize: item,
                        paginationModalOpen: false,
                        pageNumber: 1
                      });
                    }}
                  >
                    {item}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
        <Modal
          size={`${this.props.isBuilderOpen ? 'xl' : 'lg'}`}
          isOpen={this.props.modalOpen || this.props.isDiscarded}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeModal())}>
            <p className='second-headline-text'>Create Content Search</p>
          </ModalHeader>
          <ModalBody>
            <SearchFormPage legalCaseId={this.props.legalCaseId} />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.search.list.rows,
    modalOpen: store.search.list.modalOpen,
    permissions: store.auth.permissions,
    count: store.search.list.count,
    isDiscarded: store.search.list.isDiscarded,
    refreshInterval: store.LegalHold.list.refreshInterval,
    isBuilderOpen: store.search.form.isBuilderOpen,
    contentSearch: store.search.form.contentSearch,
    currentTheme: store.layout.currentTheme
  };
}

export default connect(mapStateToProps)(SearchListTable);
