import { combineReducers } from 'redux';
import dashboard from 'reducers/AIStudio/DashboardReducer';
import configuration from 'reducers/AIStudio/ConfigurationReducer';
import modelManagement from 'reducers/AIStudio/ModelManagementReducer';
import emailLabeling from 'reducers/AIStudio/EmailLabelingReducer';
export default combineReducers({
  dashboard,
  configuration,
  modelManagement,
  emailLabeling
});
