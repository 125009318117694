const initialData = {
  findLoading: false,
  saveLoading: false,
  record: null,
  containers: [],
  openFormModal: false,
  legalHoldNames: [],
  legalHoldStats: {},
  currentStep: 1,
  filters: {},
  custodians: [],
  custodiansCount: 0,
  userIdentities: [],
  selectedCustodiansList: [],
  selectedExternalCustodians: [],
  allCustodiansList: [],
  allCustodiansCount: 0,
  openCustodianModal: false,
  openInternalSelectionModal: false,
  temporaryCustodiansCount: 0,
  legalHoldId: '',
  channelsList: [],
  chatLegalHold: {},
  chatLegalHoldId: '',
  openChatModal: false,
  discardChatLH: false
};

export default (state = initialData, { type, payload }) => {
  if (type === 'LEGALHOLD_FORM_FIND_STARTED') {
    return {
      ...state,
      record: null,
      findLoading: true
    };
  }

  if (type === 'LEGALHOLD_FORM_FIND_SUCCESS') {
    return {
      ...state,
      record: payload,
      findLoading: false,
      legalHoldId: payload.legalHoldId
    };
  }

  if (type === 'LEGALHOLD_FORM_FIND_ERROR') {
    return {
      ...state,
      record: null,
      findLoading: false
    };
  }

  if (type === 'LEGALHOLD_FORM_CREATE_STARTED') {
    return {
      ...state,
      saveLoading: true,
      legalHoldStats: {}
    };
  }

  if (type === 'LEGALHOLD_FORM_CREATE_SUCCESS') {
    return {
      ...state,
      saveLoading: false,
      legalHoldStats: {}
    };
  }

  if (type === 'LEGALHOLD_FORM_CREATE_ERROR') {
    return {
      ...state,
      saveLoading: false
    };
  }

  if (type === 'LEGALHOLD_FORM_UPDATE_STARTED') {
    return {
      ...state,
      saveLoading: true
    };
  }

  if (type === 'LEGALHOLD_FORM_UPDATE_SUCCESS') {
    return {
      ...state,
      saveLoading: false
    };
  }

  if (type === 'LEGALHOLD_FORM_UPDATE_ERROR') {
    return {
      ...state,
      saveLoading: false
    };
  }

  if (type === 'LEGALHOLD_FORM_FIND_CONTAINERS_SUCCESS') {
    return {
      ...state,
      containers: payload
    };
  }

  if (type === 'LEGALHOLD_FORM_FIND_MESSAGE_SUCCESS') {
    return {
      ...state,
      messages: payload
    };
  }

  if (type === 'LEGAL_HOLD_FORM_FIND_NAMES_SUCCESS') {
    return {
      ...state,
      legalHoldNames: payload
    };
  }

  if (type === 'LEGAL_HOLD_FORM_FIND_NAMES_ERROR') {
    return {
      ...state,
      legalHoldNames: []
    };
  }

  if (type === 'LEGALHOLD_FORM_OPEN_CONFIRM') {
    return {
      ...state,
      openFormModal: true
    };
  }

  if (type === 'LEGALHOLD_FORM_CLOSE_CONFIRM') {
    return {
      ...state,
      openFormModal: false,
      legalHoldStats: {},
      currentStep: 1,
      selectedCustodiansList: [],
      selectedExternalCustodians: []
    };
  }

  if (type === 'LEGAL_HOLD_FORM_GET_STATISTICS_SUCCESS') {
    return {
      ...state,
      legalHoldStats: payload
    };
  }

  if (type === 'LEGAL_HOLD_FORM_CURRENT_STEP') {
    return {
      ...state,
      currentStep: payload
    };
  }

  if (type === 'LEGALHOLD__DISCARD_SUCCESS') {
    return {
      ...state,
      legalHoldStats: {}
    };
  }

  if (type === 'LEGAL_HOLD_DATA_TO_SUBMIT') {
    return {
      ...state,
      filters: payload
    };
  }

  if (type === 'CUSTODIANS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      custodians: payload.custodians,
      custodiansCount: payload.custodiansCount
    };
  }

  if (type === 'CUSTODIANS_LIST_FETCH_ERROR') {
    return {
      ...state,
      custodians: [],
      custodiansCount: 0
    };
  }

  if (type === 'CUSTODIAN_IDENTITIES_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      userIdentities: payload.userIdentities
    };
  }

  if (type === 'CUSTODIAN_IDENTITIES_LIST_FETCH_ERROR') {
    return {
      ...state,
      userIdentities: []
    };
  }

  if (type === 'SELECTED_CUSTODIANS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      selectedCustodiansList: payload.selectedCustodians
    };
  }

  if (type === 'SELECTED_CUSTODIANS_LIST_FETCH_ERROR') {
    return {
      ...state,
      selectedCustodiansList: []
    };
  }

  if (type === 'SELECTED_EXTERNAL_CUSTODIANS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      selectedExternalCustodians: payload.selectedExternalCustodians
    };
  }

  if (type === 'SELECTED_EXTERNAL_CUSTODIANS_LIST_FETCH_ERROR') {
    return {
      ...state,
      selectedExternalCustodians: []
    };
  }

  if (type === 'ALL_CUSTODIANS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      allCustodiansList: payload.allCustodiansList,
      allCustodiansCount: payload.allCustodiansCount
    };
  }

  if (type === 'ALL_CUSTODIANS_LIST_FETCH_ERROR') {
    return {
      ...state,
      allCustodiansList: [],
      allCustodiansCount: 0
    };
  }

  if (type === 'LEGALHOLD_FORM_CUSTODIAN_SELECTION_OPEN') {
    return {
      ...state,
      openCustodianModal: true
    };
  }

  if (type === 'LEGALHOLD_FORM_CUSTODIAN_SELECTION_CLOSE') {
    return {
      ...state,
      openCustodianModal: false
    };
  }

  if (type === 'LEGALHOLD_FORM_INTERNAL_CUSTODIAN_SELECTION_OPEN') {
    return {
      ...state,
      openInternalSelectionModal: true
    };
  }

  if (type === 'LEGALHOLD_FORM_INTERNAL_CUSTODIAN_SELECTION_CLOSE') {
    return {
      ...state,
      openInternalSelectionModal: false,
      openCustodianModal: false,
      temporaryCustodiansCount: 0
    };
  }

  if (type === 'LEGALHOLD_FORM_SET_SELECTED_CUSTODIANS_COUNT') {
    return {
      ...state,
      temporaryCustodiansCount: payload.count
    };
  }

  if (type === 'CALCULATE_STATS_SUCCESS') {
    return {
      ...state,
      legalHoldId: payload
    };
  }

  if (type === 'ALL_CHANNELS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      channelsList: payload.channelsList
    };
  }

  if (type === 'ALL_CHANNELS_LIST_FETCH_ERROR') {
    return {
      ...state,
      channelsList: []
    };
  }

  if (type === 'LEGALHOLD_CHAT_CONFIRM_OR_DISCARD_SUCCESS') {
    return {
      ...state,
      chatLegalHold: payload.chatLegalHold
    };
  }

  if (type === 'LEGALHOLD_CHAT_CONFIRM_OR_DISCARD_ERROR') {
    return {
      ...state,
      chatLegalHold: {}
    };
  }

  if (type === 'LEGALHOLD_CHAT_FORM_CREATE_SUCCESS') {
    return {
      ...state,
      chatLegalHoldId: payload.chatLegalHoldId
    };
  }

  if (type === 'LEGALHOLD_CHAT_FORM_CREATE_ERROR') {
    return {
      ...state,
      chatLegalHoldId: ''
    };
  }

  if (type === 'LEGALHOLD_CHAT_FORM_OPEN_CONFIRM') {
    return {
      ...state,
      openChatModal: true,
      chatLegalHold: {}
    };
  }

  if (type === 'LEGALHOLD_CHAT_FORM_CLOSE_CONFIRM') {
    return {
      ...state,
      openChatModal: false,
      chatLegalHold: {},
      currentStep: 1,
      selectedCustodiansList: [],
      selectedExternalCustodians: []
    };
  }

  if (type === 'LEGALHOLD_CHAT_DISCARD_CONFIRM') {
    return {
      ...state,
      discardChatLH: true
    };
  }
  return state;
};
