import React, { Component } from 'react';
import Widget from 'components/Widget/Widget';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import InputNumberFormItem from 'components/FormItems/items/InputNumberFormItem';
import configurationFields from './configurationFields';
import { Formik } from 'formik';
import styles from './AiStudioStyles.module.scss';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import IniValues from 'components/FormItems/iniValues';
import { connect } from 'react-redux';
import Loader from 'components/Loader/Loader';
import configActions from 'actions/AIStudio/ConfigurationActions';
import { activeSamplingEnum } from 'actions/common';

export class Configuration extends Component {
  state = {
    selectedFileName: '',
    fileType: '',
    uploadedFile: '',
    autoMapLLm: false,
    initialConfiguration: {},
    confidenceRed: {
      minValue: '',
      maxValue: ''
    },
    confidenceYellow: {
      minValue: '',
      maxValue: ''
    },
    confidenceGreen: {
      minValue: '',
      maxValue: ''
    },
    error: ''
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(configActions.doGetConfigurationData()).then(() => {
      this.setState({
        initialConfiguration: this.props.configurationData,
        autoMapLLm: this.props?.configurationData?.autoMapLLMMatchingSuggestions
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { configurationData } = this.props;

    if (prevProps.configurationData !== this.props.configurationData) {
      this.setState({
        confidenceRed: {
          minValue: configurationData.redMini,
          maxValue: configurationData.redMax
        },
        confidenceYellow: {
          minValue: configurationData.yellowMini,
          maxValue: configurationData.yellowMax
        },
        confidenceGreen: {
          minValue: configurationData.greenMini,
          maxValue: configurationData.greenMax
        }
      });
    }
  }

  iniValues = () => {
    const record = {
      ...this.props.configurationData
    };
    return IniValues(configurationFields, Object.assign({}, record));
  };

  getSamplingExclusions = (event, form) => {
    const file = event.target.files[0];
    const parts = file?.name.split('.');
    const extension = parts[parts.length - 1];
    let errorMessage = '';
    if (extension !== 'csv') {
      errorMessage = '*Please upload a CSV file';
      this.setState({
        error: errorMessage
      });
    } else {
      this.setState({
        error: ''
      });
    }
    this.setState({
      selectedFileName: file?.name,
      uploadedFile: file,
      fileType: extension
    });
  };

  handleSubmit = (values) => {
    const { dispatch } = this.props;
    const formattedValues = {
      ...values,

      autoMapLLMMatchingSuggestions: this.state.autoMapLLm,
      redMini: this.state.confidenceRed.minValue,
      redMax: this.state.confidenceRed.maxValue,
      yellowMini: this.state.confidenceYellow.minValue,
      yellowMax: this.state.confidenceYellow.maxValue,
      greenMini: this.state.confidenceGreen.minValue,
      greenMax: this.state.confidenceGreen.maxValue
    };
    dispatch(configActions.doUpdateConfigurationData(formattedValues)).then(() => {
      dispatch(configActions.doGetConfigurationData()).then(() => {
        this.setState({
          initialConfiguration: this.props.configurationData,
          autoMapLLm: this.props?.configurationData?.autoMapLLMMatchingSuggestions
        });
      });
    });
  };

  handleFileUpload = () => {
    const { dispatch } = this.props;
    let error = '';
    if (this.state.fileType !== 'csv') {
      error = 'Please upload a CSV file';
      this.setState({
        error
      });
    } else {
      dispatch(configActions.doImportEmailExclusions(this.state.uploadedFile));
    }
  };

  samplingOptions = [
    { value: 0, label: 'No Sampling' },
    { value: 1, label: 'Random' },
    { value: 2, label: 'Confidence Based' }
  ];

  render() {
    return (
      <div style={{ minHeight: '700px' }}>
        {this.props.loading ? (
          <Loader />
        ) : (
          <Formik onSubmit={this.handleSubmit} initialValues={this.iniValues()}>
            {(form) => {
              return (
                <form onSubmit={form.handleSubmit}>
                  <Widget>
                    <p className={`${styles.modelHeader} ms-0`}>Sampling Options</p>
                    <div>
                      <SelectFormItem
                        name={'activeSamplingConfiguration'}
                        schema={configurationFields}
                        data={this.samplingOptions}
                        mode={'fetch'}
                      />
                    </div>
                    {form?.values?.activeSamplingConfiguration === activeSamplingEnum.Random && (
                      <div>
                        <InputNumberFormItem
                          name={'samplePercentage'}
                          schema={configurationFields}
                        />
                      </div>
                    )}

                    {form?.values?.activeSamplingConfiguration ===
                      activeSamplingEnum.ConfidenceBased && (
                      <div>
                        <InputNumberFormItem
                          name={'confidenceThreshold'}
                          schema={configurationFields}
                        />
                      </div>
                    )}

                    <div>
                      <InputNumberFormItem
                        name={'parallelInferencePerBatch'}
                        schema={configurationFields}
                      />
                    </div>
                  </Widget>
                  <Widget>
                    <p className={styles.modelHeader}>LLM Settings</p>
                    <div>
                      <InputFormItem name={'llmTenantId'} schema={configurationFields} />
                    </div>
                    <div>
                      <InputFormItem name={'llmApiUrl'} schema={configurationFields} />
                    </div>
                    <div>
                      <InputFormItem
                        name={'llmApiToken'}
                        schema={configurationFields}
                        password={'password'}
                      />
                    </div>
                    <div>
                      <InputFormItem name={'llmModelName'} schema={configurationFields} />
                    </div>
                    <p className='mt-3'>
                      <input
                        name='matchingSuggestions'
                        checked={this.state.autoMapLLm}
                        type='checkbox'
                        className={`me-2`}
                        style={{ width: '15px', height: '15px', border: '1px solid #787878' }}
                        onClick={() =>
                          this.setState({
                            autoMapLLm: !this.state.autoMapLLm
                          })
                        }
                      />
                      <label htmlFor='matchingSuggestions'>Auto Map LLM Matching Suggestions</label>
                    </p>
                    <div>
                      <InputNumberFormItem
                        name={'autoMapLLMConfidence'}
                        schema={configurationFields}
                        autoFocus
                      />
                    </div>
                  </Widget>
                  <Widget>
                    <p className={styles.modelHeader}>Sampling Exclusions</p>
                    <div className='w-100'>
                      <p style={{ marginLeft: '6px' }}>Select CSV</p>
                      <div className='d-flex align-items-center'>
                        <div className={styles.samplingExclusionsContainer}>
                          <p>
                            <input
                              name='samplingExclusions'
                              id='samplingExclusions'
                              type='file'
                              className={`${styles.hiddenFileInput}`}
                              onChange={(event) => this.getSamplingExclusions(event, form)}
                            />
                            <label htmlFor='samplingExclusions' className={styles.uploadFile}>
                              Choose File
                            </label>
                          </p>
                          {this.state.selectedFileName ? (
                            <p className={styles.selectedFile}>{this.state.selectedFileName}</p>
                          ) : (
                            <p className={styles.selectedFile}>No file chosen</p>
                          )}
                        </div>
                        <button
                          type='button'
                          className={styles.secondaryButton}
                          onClick={() => this.handleFileUpload()}
                        >
                          Upload File
                        </button>
                      </div>

                      {this.state.error !== '' && (
                        <p className={styles.errorMessage}>{this.state.error}</p>
                      )}
                    </div>
                  </Widget>
                  <Widget>
                    <div className={styles.thresholdSettingsContainer}>
                      <p className={styles.modelHeader}>Confidence Threshold Settings</p>
                      <div className={styles.thresholdGroup}>
                        <div className={styles.thresholdItem}>
                          <label>Red</label>
                          <div className={styles.rangeInput}>
                            <p className={styles.inputField}>
                              <input
                                type='number'
                                min='0'
                                value={this.state.confidenceRed.minValue}
                                onChange={(e) => {
                                  this.setState({
                                    confidenceRed: {
                                      minValue: e.target.value,
                                      maxValue: this.state.confidenceRed.maxValue
                                    }
                                  });
                                }}
                              />
                              <p className='d-flex align-items-center mb-0'> %</p>
                            </p>
                            <span>to</span>
                            <p className={styles.inputField}>
                              <input
                                type='number'
                                min='0'
                                value={this.state.confidenceRed.maxValue}
                                onChange={(e) => {
                                  this.setState({
                                    confidenceRed: {
                                      minValue: this.state.confidenceRed.minValue,
                                      maxValue: e.target.value
                                    }
                                  });
                                }}
                              />
                              <p className='d-flex align-items-center mb-0'>%</p>
                            </p>
                          </div>
                        </div>
                        <div className={styles.thresholdItem}>
                          <label>Yellow</label>
                          <div className={styles.rangeInput}>
                            <p className={styles.inputField}>
                              <input
                                type='number'
                                min='0'
                                value={this.state.confidenceYellow.minValue}
                                onChange={(e) => {
                                  this.setState({
                                    confidenceYellow: {
                                      minValue: e.target.value,
                                      maxValue: this.state.confidenceYellow.maxValue
                                    }
                                  });
                                }}
                              />
                              <p className='d-flex align-items-center mb-0'>%</p>
                            </p>

                            <span>to</span>
                            <p className={styles.inputField}>
                              <input
                                type='number'
                                min='0'
                                value={this.state.confidenceYellow.maxValue}
                                onChange={(e) => {
                                  this.setState({
                                    confidenceYellow: {
                                      minValue: this.state.confidenceYellow.minValue,
                                      maxValue: e.target.value
                                    }
                                  });
                                }}
                              />
                              <p className='d-flex align-items-center mb-0'>%</p>
                            </p>
                          </div>
                        </div>

                        <div className={styles.thresholdItem}>
                          <label>Green</label>
                          <div className={styles.rangeInput}>
                            <p className={styles.inputField}>
                              <input
                                type='number'
                                min='0'
                                value={this.state.confidenceGreen.minValue}
                                onChange={(e) => {
                                  this.setState({
                                    confidenceGreen: {
                                      minValue: e.target.value,
                                      maxValue: this.state.confidenceGreen.maxValue
                                    }
                                  });
                                }}
                              />
                              <p className='d-flex align-items-center mb-0'>%</p>
                            </p>
                            <span>to</span>
                            <p className={styles.inputField}>
                              <input
                                type='number'
                                min='0'
                                value={this.state.confidenceGreen.maxValue}
                                onChange={(e) => {
                                  this.setState({
                                    confidenceGreen: {
                                      minValue: this.state.confidenceGreen.minValue,
                                      maxValue: e.target.value
                                    }
                                  });
                                }}
                              />
                              <p className='d-flex align-items-center mb-0'>%</p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Widget>
                  <div className='d-flex justify-content-end align-content-center'>
                    <button
                      type='button'
                      className={styles.secondaryButton}
                      onClick={form.handleSubmit}
                    >
                      Save
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    currentTheme: store.layout.currentTheme,
    configurationData: store.AIStudio.configuration.configurationData,
    loading: store.AIStudio.configuration.loading
  };
}
export default connect(mapStateToProps)(Configuration);
