import React, { Component } from 'react';
import actions from 'actions/search/searchFormActions';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import FileSearchForm from './FileSearchForm';
import { contentSearchTypeEnum } from 'actions/common';

class FileSearchFormPage extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    const contentSearchType = contentSearchTypeEnum.File;
    dispatch(actions.doFindContentSearchNames(this.props.legalCaseId, contentSearchType));
    dispatch(actions.doGetFileLegalHolds(this.props.legalCaseId));
  }

  doSubmit = (data) => {
    const { dispatch, navigate } = this.props;
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    dispatch(
      actions.doCreate(
        { ...data, legalCaseId: this.props.legalCaseId },
        {
          expireonUser: {
            firstName,
            lastName,
            email,
            userName
          }
        },
        navigate
      )
    );
  };

  render() {
    return (
      <React.Fragment>
        <FileSearchForm
          saveLoading={this.props.saveLoading}
          findLoading={this.props.findLoading}
          currentUser={this.props.currentUser}
          record={{}}
          contentSearch={this.props.contentSearch}
          onSubmit={this.doSubmit}
          legalCaseId={this.props.legalCaseId}
          searchNames={this.props.searchNames}
          dispatch={this.props.dispatch}
          navigate={this.props.navigate}
          fileLegalHolds={this.props.fileLegalHolds}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(store) {
  return {
    findLoading: store.search.form.findLoading,
    saveLoading: store.search.form.saveLoading,
    record: store.search.form.record,
    currentUser: store.auth.currentUser,
    contentSearch: store.search.form.contentSearch,
    searchNames: store.search.form.searchNames,
    fileLegalHolds: store.search.form.fileLegalHolds
  };
}

export default withRouter(connect(mapStateToProps)(FileSearchFormPage));
