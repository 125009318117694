import React, { Component } from 'react';
import styles from './AiStudioStyles.module.scss';
import checkIcon from 'images/icons/aiStudio/check.svg';
import penIcon from 'images/icons/aiStudio/pen.svg';
import actions from 'actions/AIStudio/EmailLabelingActions';
import { connect } from 'react-redux';

export class EmailDetails extends Component {
  state = {
    selectedOption: '',
    emailDetailsObj: {},
    unredactEmail: false,
    filters: {}
  };

  componentDidMount() {
    this.setState({
      emailDetailsObj: this.props?.emailDetailsObj,
      filters: this.props?.filters
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.emailDetailsObj !== this.props.emailDetailsObj) {
      this.setState({
        emailDetailsObj: this.props?.emailDetailsObj,
        filters: this.props?.filters
      });
    }
  }

  handleUpdateCategory = () => {
    const { dispatch } = this.props;
    const category = this.state.selectedOption;
    const data = {
      selectedEmails: [this.props.emailDetailsObj?.emailId],
      category
    };
    dispatch(actions.doUpdateCategory(data)).then(() => {
      dispatch(actions.doFetchEmails(this.state.filters));
      dispatch(actions.doCloseDetailsModal());
    });
  };

  render() {
    const categoryOptions = [
      { label: 'Select...', value: '' },
      { label: 'Business Relevant', value: 'Business Relevant' },
      { label: 'ROT', value: 'ROT' },
      { label: 'Privileged', value: 'Privileged' },
      { label: 'Sensitive', value: 'Sensitive' },
      { label: 'System Generated', value: 'System Generated' }
    ];

    return (
      <div className={styles.emailBody}>
        <div className='d-flex align-items-start mb-2'>
          <p style={{ fontSize: '14px', marginRight: '4px' }}>SUBJECT:</p>
          <p>
            {this.state.unredactEmail
              ? this.state.emailDetailsObj?.subject
              : this.state.emailDetailsObj?.anonymizedSubject}
          </p>
        </div>
        <div
          className={styles.emailDetailsContainer}
          dangerouslySetInnerHTML={{
            __html: this.state.unredactEmail
              ? this.state.emailDetailsObj?.body
              : this.state.emailDetailsObj?.anonymizedBody
          }}
        ></div>
        <div>
          <hr className={styles.divider} />
          <div className={styles.footer}>
            <p>
              <button
                type='button'
                className={styles.secondaryButton}
                onClick={() => {
                  this.setState({
                    unredactEmail: !this.state.unredactEmail
                  });
                }}
              >
                <img alt={'pen'} width={17} height={17} src={penIcon} className='me-2' />
                {this.state.unredactEmail ? 'Redact Email' : ' Unredact Email'}
              </button>
            </p>
            <div className='d-flex justify-content-between align-items-center'>
              <p className='d-flex align-items-center justify-content-center me-1 mb-0'>
                Apply Category
              </p>
              <select
                value={this.state.selectedOption}
                onChange={(e) => {
                  this.setState({
                    selectedOption: e.target.value
                  });
                }}
                className={`${styles.secondaryButton} pe-2 me-2`}
                style={{ width: '100px' }}
                placeholder='Select...'
              >
                {categoryOptions.map((option) => (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>

              <button
                type='button'
                className={styles.primaryButton}
                onClick={() => this.handleUpdateCategory()}
              >
                <img alt={'check'} width={17} height={17} src={checkIcon} className='me-2' />
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    openDetailsModal: store.AIStudio.emailLabeling.openDetailsModal
  };
}

export default connect(mapStateToProps)(EmailDetails);
