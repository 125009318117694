import Errors from 'components/FormItems/error/errors';
import { toast } from 'react-toastify';
import config from '../../config';
import { Client } from '../../legal-api';
import SearchListActions from '../search/searchListActions';
import { downloadService } from 'services/downloadService';
import { MESSAGES, contentSearchTypeEnum } from '../common';
import { store } from 'index';
import { defaultOptions } from '../../services/userService';
import { isLegalAdmin } from 'utils/isLegalAdmin';

async function doCreateSearchContent(data, expireonUser) {
  const url = `${config.externalApi}/legal/api/ContentSearch`;

  const response = await fetch(url, {
    ...defaultOptions,
    method: 'POST',
    body: JSON.stringify({ ...data, ...expireonUser })
  });

  return await response.json();
}

async function doFindSearchContent(
  contentSearchId,
  legalCaseId,
  searchString,
  pageSize,
  pageNumber
) {
  const currentUser = store.getState().auth?.currentUser;
  const legalAdmin = isLegalAdmin(currentUser);
  const loggedUser = currentUser?.userName;
  const contentSearchItems = await new Client(config.externalApi).searchItemsPOST({
    legalCaseId,
    contentSearchId,
    pageSize,
    page: pageNumber
  });
  let contentSearchGET = await new Client(config.externalApi).contentSearchStatsWithExcluded(
    legalCaseId,
    contentSearchId,
    loggedUser
  );
  if (!contentSearchGET) {
    contentSearchGET = await new Client(config.externalApi).contentSearchGET(
      legalCaseId,
      contentSearchId
    );
  }
  const contentSearchGETItem = await new Client(config.externalApi).contentSearchAll(
    legalCaseId,
    undefined,
    undefined,
    searchString,
    undefined,
    9999,
    loggedUser,
    legalAdmin
  );
  const contentSearchName = contentSearchGETItem.contentSearches.find(
    (item) => item.contentSearchId === contentSearchId
  ).name;
  return Object.assign(
    contentSearchGET || {},
    { name: contentSearchName },
    { items: contentSearchItems.contentSearchItems }
  );
}

async function doFindItemContent(legalCaseId, itemId, expireonUser) {
  const itemContent = await new Client(config.externalApi).contentSearchItemContentGET(
    legalCaseId,
    itemId,
    JSON.stringify(expireonUser)
  );
  return itemContent;
}

async function doExcludeItemFunc(contentSearchId, itemIds) {
  const itemContent = await new Client(config.externalApi).contentSearchExclude({
    contentSearchId,
    itemIds
  });
  return itemContent;
}

async function doIncludeItemFunc(contentSearchId, itemIds) {
  const itemContent = await new Client(config.externalApi).contentSearchInclude({
    contentSearchId,
    itemIds
  });
  return itemContent;
}

async function doFindItemsMount(caseId, contentSearchId, searchString, pageSize, pageNumber) {
  const contentSearchItems = await new Client(config.externalApi).contentSearchStats(
    caseId,
    contentSearchId,
    pageSize,
    pageNumber
  );
  return contentSearchItems;
}

async function doFindItems(caseId, contentSearchId, searchString, pageSize, pageNumber) {
  const items = await new Client(config.externalApi).contentSearchGETSearchItems(
    caseId,
    contentSearchId,
    searchString,
    pageSize,
    pageNumber
  );
  return items;
}

async function getExcludedStats(caseId, contentSearchId) {
  const loggedUser = store.getState().auth.currentUser?.userName;
  const stats = await new Client(config.externalApi).contentSearchStatsWithExcluded(
    caseId,
    contentSearchId,
    loggedUser
  );
  return stats;
}

async function getContentSearch(contentSearchId, expireonUser) {
  const expireonUserString = JSON.stringify(expireonUser);
  const url = `${config.externalApi}/legal/api/ContentSearch/${contentSearchId}?expireonUserString=${expireonUserString}`;
  const response = await fetch(url, {
    ...defaultOptions,
    method: 'GET'
  });
  return response.json();
}

async function doFindSearchItems(formattedValues) {
  const response = await new Client(config.externalApi).searchItemsPOST(formattedValues);
  return response;
}

async function doGetFuzzynessTypes() {
  const url = `${config.externalApi}/legal/api/ContentSearch/GetFuzzynessTypes`;
  const response = await fetch(url, {
    ...defaultOptions,
    method: 'GET'
  });
  return response.json();
}

async function downloadItemPreview(downloadUrl, currentUser) {
  const { name: firstName, surName: lastName, email, userName } = currentUser || {};
  const data = { firstName, lastName, email, userName };

  await downloadService(downloadUrl, 'PUT', data);
}

async function doFetchContentSearchNames(legalCaseId, contentSearchType) {
  const currentUser = store.getState().auth.currentUser;
  const url = `${config.externalApi}/legal/api/ContentSearch/GetContentSearchNames?legalCaseId=${legalCaseId}&loggedUser=${currentUser.userName}&contentSearchTypeEnum=${contentSearchType}`;
  const response = await fetch(url, {
    ...defaultOptions,
    method: 'GET'
  });
  return response.json();
}

async function getAllTags() {
  const url = `${config.externalApi}/api/Tags/GetAllTags`;
  const response = await fetch(url, {
    ...defaultOptions,
    method: 'GET'
  });
  return response.json();
}

async function getAllTagGroups() {
  const url = `${config.externalApi}/api/Tags/GetAllTagGroups`;
  const response = await fetch(url, {
    ...defaultOptions,
    method: 'GET'
  });
  return response.json();
}

async function getFileLegalHolds(legalCaseId) {
  const currentUser = store.getState().auth?.currentUser;
  const loggedUser = currentUser?.userName;
  const url = `${config.externalApi}/legal/api/ContentSearch/GetFileLegalHolds?legalCaseId=${legalCaseId}&loggedUser=${loggedUser}`;
  const response = await fetch(url, {
    ...defaultOptions,
    method: 'GET'
  });
  return response.json();
}

const actions = {
  doNew: () => {
    return {
      type: 'SEARCH_FORM_RESET'
    };
  },

  doGetExcludedStats: (id, contentSearchId, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: 'SEARCH_FORM_FIND_EXCLUDED_STATS_STARTED'
      });

      getExcludedStats(id, contentSearchId).then((res) => {
        const record = res;

        dispatch({
          type: 'SEARCH_FORM_FIND_EXCLUDED_STATS_SUCCESS',
          payload: record
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SEARCH_FORM_FIND_EXCLUDED_STATS_ERROR'
      });
      navigate('/app/search');
    }
  },

  doFindItemsMount:
    (id, contentSearchId, searchString, pageSize, pageNumber, navigate) => async (dispatch) => {
      try {
        dispatch({
          type: 'SEARCH_FORM_ITEMS_FIND_STARTED'
        });

        doFindItemsMount(id, contentSearchId, searchString, pageSize, pageNumber).then((res) => {
          const response = res;

          dispatch({
            type: 'SEARCH_FORM_ITEMS_FIND_SUCCESS',
            payload: {
              rows: response.contentSearchItems,
              count: response.totalCount
            }
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'SEARCH_FORM_ITEMS_FIND_ERROR'
        });
        navigate('/app/search');
      }
    },

  doFindSearchItems: (formattedValues, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: 'SEARCH_FORM_ITEMS_FIND_STARTED'
      });

      doFindSearchItems(formattedValues).then((res) => {
        const response = res;
        if (response.statusCode === 204) {
          toast.dismiss();
          toast.error(MESSAGES.LIMIT_REACHED, { icon: false });
          dispatch({
            type: 'SEARCH_FORM_ITEMS_FIND_LIMIT_REACHED'
          });
        } else {
          dispatch({
            type: 'SEARCH_FORM_ITEMS_FIND_SUCCESS',
            payload: {
              rows: response.contentSearchItems,
              count: response.totalCount
            }
          });
        }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SEARCH_FORM_ITEMS_FIND_ERROR'
      });
      navigate('/app/search');
    }
  },

  doFindItemsMountBulk:
    (id, contentSearchId, searchString, pageSize, pageNumber, navigate) => async (dispatch) => {
      try {
        dispatch({
          type: 'SEARCH_FORM_ITEMS_FIND_STARTED'
        });

        doFindItemsMount(id, contentSearchId, searchString, pageSize, pageNumber).then((res) => {
          const response = res;

          dispatch({
            type: 'SEARCH_FORM_ITEMS_BULK_FIND_SUCCESS',
            payload: {
              rows: response.contentSearchItems,
              count: response.totalCount
            }
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'SEARCH_FORM_ITEMS_FIND_ERROR'
        });
        navigate('/app/search');
      }
    },

  doFindItems:
    (id, contentSearchId, searchString, pageSize, pageNumber, navigate) => async (dispatch) => {
      try {
        dispatch({
          type: 'SEARCH_FORM_ITEMS_FIND_STARTED'
        });

        doFindItems(id, contentSearchId, searchString, pageSize, pageNumber).then((res) => {
          const record = res;

          dispatch({
            type: 'SEARCH_FORM_ITEMS_FIND_SUCCESS',
            payload: record
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'SEARCH_FORM_ITEMS_FIND_ERROR'
        });
        navigate('/app/search');
      }
    },

  doFindItemsBulk:
    (id, contentSearchId, searchString, pageSize, pageNumber, navigate) => async (dispatch) => {
      try {
        dispatch({
          type: 'SEARCH_FORM_ITEMS_BULK_FIND_STARTED'
        });

        doFindItems(id, contentSearchId, searchString, pageSize, pageNumber).then((res) => {
          const response = res;

          dispatch({
            type: 'SEARCH_FORM_ITEMS_BULK_FIND_SUCCESS',
            payload: {
              rows: response.contentSearchItems,
              count: response.totalCount
            }
          });
        });
      } catch (error) {
        Errors.handle(error);
        dispatch({
          type: 'SEARCH_FORM_ITEMS_BULK_FIND_ERROR'
        });
        navigate('/app/search');
      }
    },

  doExcludeItems: (contentSearchId, itemIds) => async (dispatch) => {
    try {
      doExcludeItemFunc(contentSearchId, itemIds).then((res) => {
        toast.success('Content Search Item excluded', { icon: false });
      });
    } catch (error) {
      Errors.handle(error);
      toast.success("Content Search Item hasn't been excluded", { icon: false });
    }
  },

  doIncludeItems: (contentSearchId, itemIds) => async (dispatch) => {
    try {
      doIncludeItemFunc(contentSearchId, itemIds).then((res) => {
        toast.success('Content Search Item included', { icon: false });
      });
    } catch (error) {
      Errors.handle(error);
      toast.success("Content Search Item hasn't been included", { icon: false });
    }
  },

  doAddIconToItem: (itemIds, status) => async (dispatch) => {
    dispatch({
      type: 'SEARCH_FORM_ADD_ICON',
      payload: {
        itemIds,
        status
      }
    });
  },

  doResetItemIcons: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_FORM_RESET_ICONS'
    });
  },

  doFind: (id, caseId, searchString, pageSize, pageNumber, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: 'SEARCH_FORM_FIND_STARTED'
      });

      doFindSearchContent(id, caseId, searchString, pageSize, pageNumber).then((res) => {
        const record = res;

        dispatch({
          type: 'SEARCH_FORM_FIND_SUCCESS',
          payload: record
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SEARCH_FORM_FIND_ERROR'
      });
      navigate('/app/search');
    }
  },

  doFindItemContent: (legalCaseId, itemId, expireonUser, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: 'SEARCH_FORM_ITEM_CONTENT_FIND_STARTED'
      });

      doFindItemContent(legalCaseId, itemId, expireonUser).then((res) => {
        const record = res;

        dispatch({
          type: 'SEARCH_FORM_ITEM_CONTENT_FIND_SUCCESS',
          payload: record
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SEARCH_FORM_FIND_ERROR'
      });
      navigate('/app/search');
    }
  },

  doCreate: (values, expireonUser, navigate) => async (dispatch) => {
    try {
      dispatch({
        type: 'SEARCH_FORM_CREATE_STARTED'
      });
      const legalCaseId = values.legalCaseId;
      const res = await doCreateSearchContent(values, expireonUser);
      const contentSearchId = res.contentsearchid;
      dispatch({
        type: 'SEARCH_FORM_CREATE_SUCCESS',
        payload: {
          isSaved: res.isSaved
        }
      });
      dispatch(SearchListActions.doCloseConfirm());

      if (values.contentSearchType === contentSearchTypeEnum.Mail) {
        navigate(`/app/search/${contentSearchId}/${legalCaseId}`);
      }
      if (values.contentSearchType === contentSearchTypeEnum.File) {
        navigate(`/app/OneDrive/Search/Preview/${contentSearchId}`);
      }
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SEARCH_FORM_CREATE_ERROR'
      });
      navigate(`/app/LegalCase/${values.legalCaseId}`);
      toast.error('Something went wrong, please try again!', { icon: false });
    }
  },

  doFetchContentSearch: (id, expireonUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'SEARCH_FETCH_STARTED'
      });
      getContentSearch(id, expireonUser).then((res) => {
        dispatch({
          type: 'SEARCH_FETCH_SUCCESS',
          payload: {
            isSaved: res.isSaved,
            contentSearch: res
          }
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SEARCH_FETCH_ERROR'
      });
    }
  },
  doGetFuzzynessTypes: () => async (dispatch) => {
    try {
      dispatch({
        type: 'FUZZYNESS_TYPES_FETCH_STARTED'
      });
      doGetFuzzynessTypes().then((res) => {
        dispatch({
          type: 'FUZZYNESS_TYPES_FETCH_SUCCESS',
          payload: {
            fuzzynessTypes: res
          }
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'FUZZYNESS_TYPES_FETCH_ERROR'
      });
    }
  },
  handleDownloadItemPreview: (downloadUrl, currentUser) => async (dispatch) => {
    try {
      dispatch({
        type: 'ITEM_PREVIEW_DOWNLOAD_STARTED'
      });

      downloadItemPreview(downloadUrl, currentUser).then((response) => {
        dispatch({
          type: 'ITEM_PREVIEW_DOWNLOAD_SUCCESS'
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ITEM_PREVIEW_DOWNLOAD_ERROR'
      });
    }
  },
  doFindContentSearchNames: (legalCaseId, contentSearchType) => async (dispatch) => {
    try {
      dispatch({
        type: 'SEARCH_FORM_FIND_SEARCH_NAMES_STARTED'
      });

      doFetchContentSearchNames(legalCaseId, contentSearchType).then((res) => {
        dispatch({
          type: 'SEARCH_FORM_FIND_SEARCH_NAMES_SUCCESS',
          payload: res
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'SEARCH_FORM_FIND_SEARCH_NAMES_ERROR'
      });
    }
  },
  doToggleBuilder: (isBuilderOpen) => async (dispatch) => {
    dispatch({
      type: 'TOGGLE_QUERY_BUILDER',
      payload: isBuilderOpen
    });
  },
  doGetAllTags: () => async (dispatch) => {
    try {
      dispatch({
        type: 'TAG_TYPES_FETCH_STARTED'
      });
      getAllTags().then((res) => {
        dispatch({
          type: 'TAG_TYPES_FETCH_SUCCESS',
          payload: {
            allTags: res
          }
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'TAG_TYPES_FETCH_ERROR'
      });
    }
  },
  doGetAllTagGroups: () => async (dispatch) => {
    try {
      dispatch({
        type: 'TAG_GROUPS_FETCH_STARTED'
      });
      getAllTagGroups().then((res) => {
        dispatch({
          type: 'TAG_GROUPS_FETCH_SUCCESS',
          payload: {
            allTagGroups: res
          }
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'TAG_GROUPS_FETCH_ERROR'
      });
    }
  },
  doOpenTagCard: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_TAG_CARD_OPEN_CONFIRM'
    });
  },
  doCloseTagCard: () => async (dispatch) => {
    dispatch({
      type: 'SEARCH_TAG_CARD_CLOSE_CONFIRM'
    });
  },
  doGetFileLegalHolds: (legalCaseId) => async (dispatch) => {
    try {
      dispatch({
        type: 'FILE_LEGAL_HOLDS_FETCH_STARTED'
      });
      getFileLegalHolds(legalCaseId).then((res) => {
        dispatch({
          type: 'FILE_LEGAL_HOLDS_FETCH_SUCCESS',
          payload: {
            fileLegalHolds: res
          }
        });
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'FILE_LEGAL_HOLDS_FETCH_ERROR'
      });
    }
  }
};

export default actions;
