import * as dataFormat from 'components/CRUD/LegalHold/list/LegalHoldDataFormatters';
import actions from 'actions/OneDrive/OneDrivePreviewActions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Row
} from 'reactstrap';
import actionsLC from 'actions/LegalCase/LegalCaseFormActions';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import plusIcon from 'images/icons/plus.svg';
import syncIcon from 'images/icons/sync.svg';
import barsIcon from 'images/bars.svg';
import { FormattingService } from 'utils/sizeFormatting';
import formActions from 'actions/OneDrive/OneDriveFormActions';
import OneDriveFormPage from '../form/OneDriveFormPage';
import Pagination from 'react-js-pagination';
import styles from '../../LegalHold/list/LegalHoldList.module.scss';
import config from 'config';
import searchIcon from 'images/icons/search.svg';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
import caretDown from 'images/icons/caret-down.svg';
import caretUp from 'images/icons/caret-up.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { GrOnedrive } from 'react-icons/gr';
import listActions from 'actions/LegalHold/LegalHoldListActions';
import trashIcon from 'images/icons/trash.svg';
import { toast } from 'react-toastify';
import Loader from 'components/Loader/Loader';

class OneDriveListTable extends Component {
  intervalID = 0;
  interval = 0;
  state = {
    dropdownItem: [],
    isIndexing: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 5,
    newRows: [],
    searchString: '',
    legalHoldId: '',
    sortOrder: '',
    expandExport: false
  };

  resetDropdowns(e) {
    if (e.target?.getAttribute('alt') != 'bar') {
      this.setState({
        dropdownItem: []
      });
    }
  }

  openFormModal() {
    const { dispatch } = this.props;
    dispatch(formActions.doOpenFileLH());
  }

  closeFormModal() {
    this.props.dispatch(formActions.doCloseFileLH());
  }

  actionFormatter(cell, row) {
    const { navigate, permissions, caseStatus } = this.props;
    return (
      <Dropdown group isOpen={this.state.dropdownItem.includes(cell)} size='lg' toggle={() => {}}>
        <DropdownToggle className={'btn bg-transparent border-0'}>
          <img src={barsIcon} alt='bar' width={'24px'} height={'24px'} />
        </DropdownToggle>
        <DropdownMenu>
          {permissions['LegalUI.LegalHold'] && (
            <button
              disabled={!row?.legalHoldStatistics?.isResultValid}
              className='btn bg-transparent text-white first-body-text d-flex align-items-center w-100'
              type='button'
              onClick={() => {
                navigate(`/app/OneDrive/Preview/${row.legalHoldId}`);
              }}
            >
              <GrOnedrive style={{ color: 'white' }} className={'me-2'} />
              <p className={'mb-0'}>Preview</p>
            </button>
          )}

          {caseStatus !== config.caseStatus.Closed && (
            <button
              className={`btn bg-transparent text-white first-body-text d-flex align-items-center w-100 `}
              type='button'
              onClick={() => {
                this.setState({
                  legalHoldId: cell
                });
                this.props
                  .dispatch(
                    formActions.doDiscardLegalHold(
                      row?.legalCaseId,
                      row?.legalHoldId,
                      this.props?.currentUser
                    )
                  )
                  .then(() => {
                    toast.success('Legal Hold discarded', { icon: false });
                    this.props.dispatch(
                      actions.doFetchFilesLH({
                        legalCaseId: row?.legalCaseId,
                        searchString: this.state.searchString,
                        pageNumber: this.state.pageNumber,
                        pageSize: this.state.pageSize,
                        sortOrder: this.state.sortOrder
                      })
                    );
                  });
              }}
            >
              <img
                src={trashIcon}
                alt={'sync'}
                width={'14px'}
                height={'14px'}
                className={'me-2 '}
              />
              <p className={'mb-0'}>Discard</p>
            </button>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }

  refreshTable = () => {
    const { dispatch, navigate, legalCaseId } = this.props;
    const interval = this.props?.refreshInterval.concat('000');
    const refreshInterval = JSON.parse(interval);
    this.intervalID = setInterval(() => {
      dispatch(actionsLC.doGetStats(legalCaseId, navigate));
      dispatch(
        actions.doFetchFilesLH({
          legalCaseId,
          searchString: this.state.searchString,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          totalCount: this.props.count,
          newRows: this.props.rows
        });
      });
    }, refreshInterval);
  };

  componentDidMount() {
    const { dispatch, legalCaseId } = this.props;
    dispatch(
      actions.doFetchFilesLH({
        searchString: this.state.searchString,
        legalCaseId,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        totalCount: this.props.count,
        newRows: this.props.rows
      });
    });
    window.addEventListener('click', (e) => this.resetDropdowns(e));
    if (this.props.refreshInterval !== null) {
      this.refreshTable();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, legalCaseId } = this.props;
    if (prevProps.refreshInterval !== this.props.refreshInterval) {
      this.refreshTable();
    }
    if (
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.pageSize !== this.state.pageSize ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.sortOrder !== this.state.sortOrder
    ) {
      dispatch(
        actions.doFetchFilesLH({
          legalCaseId,
          searchString: this.state.searchString,
          pageSize: this.state.pageSize,
          pageNumber: this.state.pageNumber,
          sortOrder: this.state.sortOrder
        })
      ).then(() => {
        this.setState({
          newRows: this.props.rows,
          totalCount: this.props.count
        });
      });
    }
    if (prevProps.rows !== this.props.rows) {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
    clearInterval(this.interval);
    window.removeEventListener('click', (e) => this.resetDropdowns(e));
  }

  indexProgressFormatter = (item, row) => {
    if (!item) {
      return null;
    }
    const indexedItemsCountPartial = Math.floor((item?.indexedItemsCount / item?.itemCount) * 100);
    const failedItemsCountPartial = Math.floor(
      (item?.finallyFailedItemsCount / item?.itemCount) * 100
    );
    const legalHoldId = row.legalHoldId;
    if (row.isIndexed || row.indexingQueued) {
      return (
        <Progress multi>
          <Progress
            bar
            color='warning'
            animated
            value={failedItemsCountPartial}
            style={{ position: 'relative' }}
          >
            <span
              style={{
                left: 'calc(100% / 2 - 10px)',
                position: 'absolute'
              }}
            >
              {failedItemsCountPartial} %
            </span>
          </Progress>
          <Progress
            bar
            animated={indexedItemsCountPartial < 100}
            value={indexedItemsCountPartial}
            style={{ position: 'relative' }}
          >
            <span
              style={{
                left: 'calc(100% / 2 - 10px)',
                position: 'absolute'
              }}
            >
              {indexedItemsCountPartial} %
            </span>
          </Progress>
        </Progress>
      );
    } else {
      return (
        <p className='d-flex justify-content-center align-items-center mb-0'>
          {/* //Hide Index button for file LH until backend logic is integrated */}
          {/* {this.props.permissions['LegalUI.LegalHold.Index'] &&
            this.props.caseStatus !== config.caseStatus.Closed && (
              <p
                style={{
                  display:
                    !row.legalHoldStatistics ||
                    row.legalHoldStatistics?.itemCount === 0 ||
                    row.indexingQueued ||
                    row.isIndexed ||
                    !row.legalHoldConfirmed ||
                    (this.props.legalCase?.restoreInProgress &&
                      !this.props.legalCase?.restoreComplete)
                      ? 'none'
                      : 'block',
                  marginBottom: '0px'
                }}
              >
                <button
                  className={`btn text-white first-body-text d-flex align-items-center ${styles.indexButton}`}
                  type='button'
                  onClick={() => {
                    this.setState({
                      cell: legalHoldId,
                      isIndexing: [...this.state.isIndexing, legalHoldId]
                    });
                  }}
                >
                  <img
                    src={syncIcon}
                    alt={'sync'}
                    width={'16px'}
                    height={'16px'}
                    className={'me-2 '}
                  />
                  <p className={'mb-0'}>Index</p>
                </button>
              </p>
            )} */}
        </p>
      );
    }
  };

  itemsOnHoldProgressFormatter = (cell, row) => {
    if (!cell) {
      return null;
    }
    let value = Math.floor((cell / row?.legalHoldStatistics?.itemCount) * 100);
    if (isNaN(value)) {
      value = 0;
    }
    return (
      <Progress animated={value < 100} value={value} style={{ position: 'relative' }}>
        <span
          style={{
            left: 'calc(100% / 2 - 10px)',
            position: 'absolute'
          }}
        >
          {value} %
        </span>
      </Progress>
    );
  };

  handleSearch = () => {
    const { dispatch, legalCaseId } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doFetchFilesLH({
        legalCaseId,
        searchString: this.state.searchString,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        sortOrder: this.state.sortOrder
      })
    ).then(() => {
      this.setState({
        newRows: this.props.rows,
        totalCount: this.props.count
      });
    });
  };

  sortFormatter(sortField) {
    const SortEnum = {
      legalHoldName: 'legalholdname',
      legalHoldDescription: 'description',
      creationDate: 'creationdate',
      itemSizeInBytes: 'size',
      itemCount: 'count',
      finallyFailedItemsCount: 'faileditemscount',
      legalHoldConfirmed: 'legalholdconfirmed'
    };
    return SortEnum[sortField];
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  getContainerIds = (data) => {
    const containerIds = [];
    data.map((item) => {
      containerIds.push(item.containerId);
    });
    return containerIds;
  };

  formatData = (data) => {
    const custodianIds = [];
    data?.custodians?.map((item) => {
      custodianIds.push(item?.custodianId);
    });
    const containerIds = this.getContainerIds(data?.containers);
    const dataToSubmit = {
      legalHoldName: data.legalHoldName,
      legalHoldDescription: data.legalHoldDescription,
      legalCaseId: data?.legalCaseId,
      custodians: custodianIds,
      externalCustodians: data.externalCustodians,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      subject: data.subject,
      internalOnly: data.internalOnly,
      incoming: data.incoming,
      outgoing: data.outgoing,
      noOtherInternalRecipient: data.noOtherInternalRecipient,
      messageClass: data.messageClass,
      subjectLine: data.subjectLine,
      attachmentNames: data.attachmentNames,
      attachmentExtensions: data.attachmentExtensions,
      containerIds
    };
    return dataToSubmit;
  };

  toggleExport = () => {
    this.setState({
      expandExport: !this.state.expandExport
    });
  };

  exportMenu = () => {
    return (
      <Dropdown
        isOpen={this.state.expandExport}
        className={`${styles.exportButton}`}
        toggle={this.toggleExport}
      >
        <DropdownToggle
          className={`${styles.exportButton} bg-transparent text-white mb-0`}
          disabled={this.state.totalCount === 0}
        >
          Export
          {!this.state.expandExport ? (
            <img src={caretDown} alt={'uncollapsed'} width='20px' height='20px' className='ms-2' />
          ) : (
            <img src={caretUp} alt={'collapsed'} width='20px' height='20px' className='ms-2' />
          )}
        </DropdownToggle>
        <DropdownMenu className='p-0 bg-transparent'>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportCSV}>
            CSV
          </DropdownItem>
          <DropdownItem className={`${styles.exportItem}`} onClick={this.handleExportPDF}>
            PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  columnsToExport = [
    'legalHoldName',
    'legalHoldDescription',
    'creationDate',
    'itemSizeInBytes',
    'itemCount',
    'finallyFailedItemsCount',
    'legalHoldConfirmed'
  ];

  handleExportCSV = () => {
    const { dispatch, currentUser, legalCaseId } = this.props;
    const url = `${config.externalApi}/legal/api/OneDriveLegalHold/ExportLegalHolds?`;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToCSV(
        url,
        currentUser,
        legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  handleExportPDF = () => {
    const { dispatch, currentUser, legalCaseId } = this.props;
    const url = `${config.externalApi}/legal/api/OneDriveLegalHold/ExportToPdf?`;
    const columnHeaders = this.columnsToExport.join(',');
    dispatch(
      actions.handleExportToPDF(
        url,
        currentUser,
        legalCaseId,
        this.state.searchString,
        columnHeaders
      )
    );
  };

  showTiles() {
    const { dispatch } = this.props;
    dispatch(listActions.doHideTable());
  }

  render() {
    const toReadableSizeFormat = new FormattingService();
    const columns = [
      {
        dataField: 'legalHoldName',
        text: 'Hold Name',
        sort: true,
        formatter: (cell, row) => (
          <div className='d-flex align-items-center'>
            <div className={styles.holdListName}>{cell}</div>
          </div>
        )
      },
      {
        dataField: 'legalHoldDescription',
        text: 'Description',
        sort: true,
        formatter: (cell, row) => <div className={styles.holdListDescription}>{cell}</div>
      },
      {
        dataField: 'creationDate',
        text: 'Created',
        formatter: dataFormat.dateTimeFormatter,
        sort: true
      },
      {
        dataField: 'itemSizeInBytes',
        text: 'Volume',
        formatter: (cell, row) =>
          row?.legalHoldStatistics?.isResultValid ? (
            dataFormat.sizeFormatter(row.legalHoldStatistics?.itemSizeInBytes)
          ) : (
            <div className='spinner-border text-light' role='status' />
          ),
        sort: true,
        csvFormatter: (item) => (item !== null ? dataFormat.sizeFormatter(item) : null)
      },
      {
        dataField: 'itemCount',
        text: 'Messages',
        formatter: (cell, row) =>
          row?.legalHoldStatistics?.isResultValid ? (
            toReadableSizeFormat.numberWithCommas(row?.legalHoldStatistics?.itemCount)
          ) : (
            <div className='spinner-border text-light' role='status' />
          ),
        sort: true,
        csvFormatter: (item) => (item !== null ? toReadableSizeFormat.numberWithCommas(item) : null)
      },
      {
        dataField: 'legalHoldStatistics.itemsOnLegalHoldCount',
        text: 'Items on Hold Progress',
        formatter: (cell, row) => {
          return this.itemsOnHoldProgressFormatter.bind(this)(cell, row);
        },
        csvExport: false
      },
      {
        dataField: 'legalHoldStatistics',
        text: 'Indexing Progress',
        formatter: (cell, row) => {
          return this.indexProgressFormatter.bind(this)(cell, row);
        },
        csvExport: false
      },
      {
        dataField: 'finallyFailedItemsCount',
        text: 'Failed Index Items',
        sort: true,
        formatter: (cell, row) => {
          return (
            <div style={{ paddingLeft: '25px' }}>
              {row?.legalHoldStatistics?.finallyFailedItemsCount}
            </div>
          );
        },
        csvFormatter: (item) => (item !== null ? toReadableSizeFormat.numberWithCommas(item) : null)
      },
      {
        dataField: 'legalHoldConfirmed',
        text: 'Legal Hold Confirmed',
        sort: true,
        formatter: (cell) => <div>{cell ? 'Yes' : 'No'}</div>,
        csvFormatter: (cell, row) => (cell ? 'Yes' : 'No')
      },
      {
        dataField: 'legalHoldId',
        text: 'Actions',
        csvExport: false,
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (this.state.dropdownItem.includes(row.legalHoldId)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.legalHoldId]
              });
            }
          }
        }
      }
    ];

    return (
      <div>
        <Row>
          <Col sm={12}>
            <ToolkitProvider
              bootstrap4
              keyField='legalHoldId'
              columns={columns}
              data={this.state.newRows}
            >
              {(props) => (
                <>
                  <Row key={'table-part'} className='mb-4'>
                    <Col style={{ marginTop: '8px' }}>
                      <div className={'d-flex align-items-center'}>
                        {this.props.permissions['LegalUI.LegalHold.FileLegalHold'] &&
                        this.props?.caseStatus !== config.caseStatus.Closed ? (
                          <button
                            className='btn first-body-text d-flex align-items-center me-3'
                            type='button'
                            onClick={() => {
                              this.openFormModal();
                            }}
                          >
                            <img src={plusIcon} alt={'plus'} className={'me-2'} />
                            <p className={'mb-0'}>New file</p>
                          </button>
                        ) : null}
                      </div>
                    </Col>
                    <Col
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '5px'
                      }}
                    >
                      <div style={{ marginRight: '5px' }}>{this.exportMenu()}</div>
                      <span>
                        <button
                          className='btn fw-semi-bold d-flex align-items-center'
                          type='button'
                          onClick={() => this.showTiles()}
                          style={{ height: '37px', marginRight: '5px' }}
                        >
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </button>
                      </span>
                      <span>
                        <input
                          type='search'
                          placeholder={'Search'}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              this.handleSearch();
                            }
                          }}
                          style={{
                            border: '0.5px solid #0046b1',
                            borderRadius: '3px'
                          }}
                          className={'form-control search-input me-5 w-200'}
                          value={this.state.searchString}
                          onChange={(e) => {
                            this.setState({
                              searchString: e.target.value
                            });
                          }}
                        />
                      </span>
                      <span>
                        <button
                          className='btn search-button-with-icon me-2 ms-10'
                          type='button'
                          onClick={this.handleSearch}
                          style={{ height: '37px', width: '37px' }}
                        >
                          <img
                            title={'search'}
                            alt={'search'}
                            width={14}
                            height={14}
                            src={searchIcon}
                          />
                        </button>
                      </span>
                    </Col>
                  </Row>
                  <div className='table-container'>
                    <BootstrapTable
                      bordered={false}
                      classes={`table-striped table-hover fs-sm custom-table`}
                      remote={{
                        filter: false,
                        pagination: false,
                        sort: true,
                        cellEdit: false
                      }}
                      onTableChange={this.handleTableChange}
                      noDataIndication={this.props?.loading ? <Loader /> : emptyDataMessage}
                      {...props.baseProps}
                    />
                  </div>
                  <Row key={'pagination'} className='mt-3'>
                    <Col>
                      <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                      {this.state.totalCount ? (
                        <Pagination
                          totalItemsCount={this.state.totalCount}
                          onChange={this.handlePageChange}
                          activePage={this.state.pageNumber}
                          itemsCountPerPage={this.state.pageSize}
                          pageRangeDisplayed={5}
                          prevPageText={'<'}
                          nextPageText={'>'}
                          firstPageText={'<<'}
                          lastPageText={'>>'}
                          linkClassFirst={styles.paginationNext}
                          linkClassPrev={styles.paginationNext}
                          linkClassNext={styles.paginationNext}
                          linkClassLast={styles.paginationNext}
                          linkClass={styles.pageLink}
                          activeLinkClass={styles.activeLinkClass}
                        />
                      ) : null}
                      <Dropdown
                        isOpen={this.state.paginationModalOpen}
                        toggle={() =>
                          this.setState({
                            paginationModalOpen: !this.state.paginationModalOpen
                          })
                        }
                        className={styles.pageSizeDropdown}
                      >
                        <DropdownToggle className='bg-transparent text-white d-flex'>
                          <p
                            style={{ marginBottom: '3px', marginRight: '2px' }}
                            className='first-body-text'
                          >
                            {this.state.pageSize}
                          </p>
                          <img
                            src={caretDown}
                            alt={'uncollapsed'}
                            width='20px'
                            height='20px'
                            className='ms-2'
                          />
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-position-fixed'>
                          {[5, 10, 25].map((item) => (
                            <DropdownItem
                              className={styles.dropdownItem}
                              key={item}
                              onClick={() => {
                                this.setState({
                                  pageSize: item,
                                  paginationModalOpen: false,
                                  pageNumber: 1
                                });
                              }}
                            >
                              {item}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                </>
              )}
            </ToolkitProvider>
          </Col>
        </Row>

        <Modal
          size='lg'
          isOpen={this.props.openFileModal}
          className={`themeStyle${this.props.currentTheme?.replace('#', '')}`}
        >
          <ModalHeader close={customCloseBtn(() => this.closeFormModal())}>
            <p className='second-headline-text'>Create Legal Hold</p>
          </ModalHeader>

          <ModalBody>
            <OneDriveFormPage
              legalCaseId={this.props.legalCaseId}
              legalHoldId={this.state.legalHoldId}
              pageNumber={this.state.pageNumber}
              pageSize={this.state.pageSize}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    rows: store.OneDrive.list.rows,
    loading: store.OneDrive.list.loading,
    permissions: store.auth.permissions,
    count: store.OneDrive.list.count,
    currentUser: store.auth.currentUser,
    refreshInterval: store.LegalHold.list.refreshInterval,
    currentTheme: store.layout.currentTheme,
    legalCase: store.LegalCase.form.record,
    openFileModal: store.OneDrive.form.openFileModal
  };
}

export default connect(mapStateToProps)(OneDriveListTable);
