import { ErrorMessage, Formik } from 'formik';
import React, { Component } from 'react';
import Loader from 'components/Loader';
import InputFormItem from 'components/FormItems/items/InputFormItem';
import SelectFormItem from 'components/FormItems/items/SelectFormItem';
import TextAreaFormItem from 'components/FormItems/items/TextAreaFormItem';
import searchFields from 'components/CRUD/Search/searchFields';
import IniValues from 'components/FormItems/iniValues';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css';
import { Button, Row, Col, Nav, NavItem, NavLink, Progress } from 'reactstrap';
import s from 'components/CRUD/LegalHold/form/Wizard.module.scss';
import styles from 'components/CRUD/Search/form/SearchForm.module.scss';
import * as Yup from 'yup';
import classnames from 'classnames';
import DatePickerFormItem from 'components/FormItems/items/DatePickerFormItem';
import InputNumberFormItem from 'components/FormItems/items/InputNumberFormItem';
import { contentSearchTypeEnum } from 'actions/common';

const count = 2;
class FileSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1,
      progress: 50
    };
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }

  nextStep = () => {
    let currentStep = this.state.currentStep;
    if (currentStep >= count) {
      currentStep = count;
    } else {
      currentStep += 1;
    }
    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  };

  previousStep = () => {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep -= 1;
    }

    this.setState({
      currentStep,
      progress: (100 / count) * currentStep
    });
  };

  iniValues = () => {
    const record = {
      ...this.props.record,
      legalHoldIds: undefined
    };
    return IniValues(searchFields, Object.assign({}, record));
  };

  FormValidationSchema = [
    Yup.object().shape({
      name: Yup.string().trim().nullable(true).required('Search Name* is a required field'),
      legalHoldIds: Yup.array()
        .required('Legal Holds* is a required field')
        .test('empty-array', 'Legal Holds* is a required field', (legalHoldIds) => {
          if (legalHoldIds?.length !== 0) return true;
        })
    })
  ];

  validateSearchName = (value) => {
    if (!value) {
      return undefined;
    }
    let error;
    this.props?.searchNames?.forEach((item) => {
      let createdSearchName = item?.toLowerCase().trim();
      let newSearchName = value?.toLowerCase().trim();
      if (createdSearchName === newSearchName) error = 'This content search name already exists';
    });
    return error;
  };

  handleSubmit = (values, actions) => {
    if (this.state.currentStep === count) {
      let formattedFileTypes = [];
      formattedFileTypes = values?.fileTypes?.trim().split(/\s*[\s,]\s*/);
      const formValues = {
        name: values.name,
        legalHoldIds: values.legalHoldIds,
        fileSizeType: values.fileSizeType,
        contentSearchType: contentSearchTypeEnum.File,
        queryString: {
          fileTypes: formattedFileTypes,
          fileLastModifiedDate: values.fileLastModifiedDate,
          fileCreationDate: values.fileCreationDate,
          minFileSize: values.minFileSize,
          maxFileSize: values.maxFileSize,
          fileName: values.fileName,
          filePath: values.filePath,
          fileContent: values.fileContent
        }
      };

      this.props.onSubmit({ ...formValues });
    } else {
      this.nextStep();
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  fileSizeTypes = [
    { label: 'Bytes', value: 'bytes' },
    { label: 'Kilobyte', value: 'kb' },
    { label: 'Megabyte', value: 'mb' },
    { label: 'Gigabyte', value: 'gb' }
  ];

  formatLegalHolds = (legalHolds) => {
    let formattedArray = [];
    Object.entries(legalHolds).map((legalHold) => {
      const item = { label: legalHold[1], value: legalHold[0] };
      formattedArray.push(item);
    });
    return formattedArray;
  };

  firstStep(form) {
    return (
      <div>
        <div>
          <InputFormItem
            name={'name'}
            schema={searchFields}
            autoFocus
            validate={this.validateSearchName}
          />
        </div>
        <div className='mt-2'>
          <SelectFormItem
            name={'legalHoldIds'}
            schema={searchFields}
            mode={'fetch'}
            data={this.formatLegalHolds(this.props?.fileLegalHolds)}
            isMulti={true}
          />
          <ErrorMessage name='legalHoldIds'>
            {(msg) => <div className={styles.errorMessage}>{msg}</div>}
          </ErrorMessage>
        </div>
      </div>
    );
  }

  secondStep = (form) => (
    <>
      <div>
        <InputFormItem name={'fileName'} schema={searchFields} autoFocus />
      </div>
      <div className='mb-2'>
        <InputFormItem name={'filePath'} schema={searchFields} autoFocus />
      </div>
      <div className='d-flex'>
        <div className='w-50'>
          <InputFormItem
            name={'fileTypes'}
            schema={searchFields}
            autoFocus
            placeholder={'.pdf .docx .html...'}
          />
        </div>
        <div className='w-50 ms-3 d-flex justify-content-between'>
          <p className='me-2 mb-0'>
            <InputNumberFormItem
              name={'minFileSize'}
              schema={searchFields}
              minValue='0'
              inputStyle={{
                height: '46px',
                width: '100px'
              }}
              autoFocus
            />
          </p>
          <p className='me-2 mb-0'>
            <InputNumberFormItem
              name={'maxFileSize'}
              schema={searchFields}
              minValue='0'
              inputStyle={{
                height: '46px',
                width: '100px'
              }}
              autoFocus
            />
          </p>
          <p className='mb-0' style={{ width: '150px' }}>
            <SelectFormItem
              name={'fileSizeType'}
              schema={searchFields}
              mode={'fetch'}
              data={this.fileSizeTypes}
            />
          </p>
        </div>
      </div>

      <div className='d-flex w-100'>
        <div className='w-50'>
          <TextAreaFormItem
            name={'fileContent'}
            schema={searchFields}
            textAreaStyle={{
              height: '110px',
              overflowY: 'scroll',
              wordBreak: 'break-word'
            }}
          />
        </div>
        <div className='w-50 ms-3'>
          <div>
            <DatePickerFormItem name={'fileCreationDate'} schema={searchFields} showTimeInput />
          </div>
          <div>
            <DatePickerFormItem name={'fileLastModifiedDate'} schema={searchFields} showTimeInput />
          </div>
        </div>
      </div>
    </>
  );

  renderStepContent = (currentStep, form) => {
    switch (currentStep) {
      case 1:
        return this.firstStep(form);
      case 2:
        return this.secondStep(form);

      default:
        return this.firstStep(form);
    }
  };

  renderForm() {
    const { saveLoading } = this.props;
    const currentStep = this.state.currentStep;
    const validateStep = this.state.currentStep - 1;
    return (
      <div className={s.root}>
        <Row>
          <Col sm={12}>
            <Nav pills justified className={s.wizardNavigation}>
              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 1
                  })}
                >
                  <small>1.</small>
                  &nbsp; General
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({
                    wizardActiveItem: currentStep === 2
                  })}
                >
                  <small>2.</small>
                  &nbsp; Optional Filters
                </NavLink>
              </NavItem>
            </Nav>
            <Progress value={this.state.progress} className='progress-xs-wizard' />
            <div>
              <div className={s.stepBody}>
                <Formik
                  onSubmit={this.handleSubmit}
                  initialValues={this.iniValues()}
                  validationSchema={this.FormValidationSchema[validateStep]}
                >
                  {(form) => {
                    return (
                      <form onSubmit={form.handleSubmit} className={styles.formBody}>
                        <div>{this.renderStepContent(this.state.currentStep, form)}</div>
                        <div>
                          <ul className={styles.wizardList}>
                            <li className='previous'>
                              <Button
                                hidden={currentStep === 1}
                                color='primary'
                                onClick={this.previousStep}
                                className='first-body-text'
                              >
                                <i className='fa fa-caret-left' />
                                &nbsp;Previous
                              </Button>
                            </li>

                            <li className='next'>
                              <Button
                                color='primary'
                                onClick={() => {
                                  form.handleSubmit();
                                }}
                                disabled={saveLoading}
                                className='first-body-text'
                              >
                                {currentStep === count ? (
                                  <span>
                                    Save <i className='fa fa-check' />
                                  </span>
                                ) : (
                                  <span>
                                    Next <i className='fa fa-caret-right' />
                                  </span>
                                )}
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { findLoading, record } = this.props;

    if (findLoading) {
      return <Loader />;
    }

    if (!record) {
      return <Loader />;
    }

    return this.renderForm();
  }
}

export default FileSearchForm;
