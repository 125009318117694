import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';
import actions from 'actions/LegalHold/LegalHoldFormActions';
import { toast } from 'react-toastify';
import backIcon from '../../../images/icons/arrow-left.svg';
import s from '../Search/itemContent/styles.module.scss';
import { rsmfUrl } from '../../../config';
import config from '../../../config';

class RsmfViewerPage extends Component {
  componentDidMount() {
    const { dispatch, navigate } = this.props;
    const legalHoldId = new URLSearchParams(this.props.location?.search).get('legalHoldId');
    if (!legalHoldId) {
      this.handleRedirect(navigate);
      return;
    }
    const { name: firstName, surName: lastName, email, userName } = this.props.currentUser || {};
    const expireonUser = {
      firstName,
      lastName,
      email,
      userName
    };
    dispatch(actions.doFind(legalHoldId, expireonUser, navigate));
    if (window.location.hash.includes('/rsmfViewer')) {
      const script = document.createElement('script');
      script.src = '/rsmf-viewer.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }

  componentWillUnmount() {
    if (this.script) {
      document.body.removeChild(this.script);
    }
  }

  discardChatLegalHold = () => {
    const { dispatch, navigate } = this.props;
    const legalHoldId = new URLSearchParams(this.props.location?.search).get('legalHoldId');
    const isConfirmed = false;
    const isChat = true;
    const legalHoldType = config.legalHoldEnum.Chat;
    dispatch(actions.doConfirmDiscardChatLH(legalHoldId, isConfirmed)).then(() => {
      const legalCaseId = this.props.chatLegalHold?.legalCaseId;
      dispatch(actions.doDiscardChatLH());
      dispatch(actions.doFindContainers(navigate, isChat, legalHoldType));
      dispatch(actions.doFindLegalHoldNames(legalCaseId, isChat));
      navigate(`/app/LegalCase/${legalCaseId}`);
      dispatch(actions.doOpenChatLH());
    });
  };

  handleRedirect = (navigate) => {
    toast.error('Something went wrong, please try again!', { icon: false });
    setTimeout(() => {
      navigate(-1);
    }, 100);
  };

  confirmChatLegalHold = () => {
    const { dispatch, navigate } = this.props;
    const isConfirmed = true;
    const legalHoldId = new URLSearchParams(this.props.location?.search).get('legalHoldId');
    dispatch(actions.doConfirmDiscardChatLH(legalHoldId, isConfirmed)).then(() => {
      const legalCaseId = this.props.chatLegalHold?.legalCaseId;
      dispatch(actions.doCloseChatLH());
      navigate(`/app/LegalCase/${legalCaseId}`);
    });
  };

  render() {
    const { navigate, record, permissions, currentUser } = this.props;
    const legalHoldId = new URLSearchParams(this.props.location?.search).get('legalHoldId');
    window.legalHoldId = legalHoldId;
    window.rsmfUrls = rsmfUrl;
    window.legalHoldConfirmed = record?.legalHoldConfirmed;
    window.expireonUser = { email: currentUser.email, name: currentUser.name };
    if (!record) {
      return null;
    }
    return (
      <>
        {permissions['LegalUI.RsmfViewer'] ? (
          <div className='fs-3 fw-semibold'>
            <div className='d-flex justify-content-between'>
              <div className={'d-flex mb-4 align-items-center'}>
                <button className={'btn bg-transparent me-2 ps-0'} onClick={() => navigate(-1)}>
                  <img src={backIcon} alt={'arrow-back'} />
                </button>
                <h4
                  className={`${s.headerFont} mb-1 first-headline-text d-flex align-items-center mt-1`}
                >
                  Communication Viewer
                </h4>
              </div>
              {/*{legalHoldId && !this.props?.record?.legalHoldConfirmed && (*/}
              {/*  <div className='d-flex'>*/}
              {/*    <button*/}
              {/*      type='button'*/}
              {/*      className='btn danger-btn fw-semi-bold d-flex align-items-center me-3'*/}
              {/*      onClick={() => this.discardChatLegalHold()}*/}
              {/*    >*/}
              {/*      Discard*/}
              {/*    </button>*/}
              {/*    <button*/}
              {/*      style={{ height: '35px' }}*/}
              {/*      className='btn fw-semi-bold d-flex align-items-center'*/}
              {/*      type='button'*/}
              {/*      onClick={() => this.confirmChatLegalHold()}*/}
              {/*    >*/}
              {/*      Confirm*/}
              {/*    </button>*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
            <rsmf-viewer />
          </div>
        ) : (
          <Navigate to='/no-access' replace />
        )}
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions,
    chatLegalHold: store.LegalHold.form.chatLegalHold,
    currentUser: store.auth.currentUser,
    record: store.LegalHold.form.record
  };
}

export default withRouter(connect(mapStateToProps)(RsmfViewerPage));
