const initialData = {
  modelsList: [],
  loading: false,
  loadingCopy: false,
  message: {}
};

export default (state = initialData, { type, payload }) => {
  if (type === 'MODELS_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'MODELS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      modelsList: payload
    };
  }

  if (type === 'MODELS_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      modelsList: []
    };
  }
  if (type === 'COPY_TO_SYNTHETIC_DATA_STARTED') {
    return {
      ...state,
      loadingCopy: true,
      message: {}
    };
  }

  if (type === 'COPY_TO_SYNTHETIC_DATA_SUCCESS') {
    return {
      ...state,
      loadingCopy: false,
      message: payload
    };
  }

  if (type === 'COPY_TO_SYNTHETIC_DATA_ERROR') {
    return {
      ...state,
      loadingCopy: false,
      message: {}
    };
  }
  return state;
};
