import auth from 'reducers/auth';
import alerts from 'reducers/auth';
import navigation from 'reducers/navigation';
import layout from 'reducers/layout';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import LegalCase from 'reducers/LegalCase/LegalCaseReducers';
import LegalHold from 'reducers/LegalHold/LegalHoldReducers';
import search from 'reducers/search/searchReducers';
import legalCaseStats from 'reducers/legalCaseStats/legalCaseStatsReducers';
import credentials from 'reducers/credentials/credentialReducers';
import Export from 'reducers/Export/ExportReducers';
import viewers from './viewers/viewersReducers';
import AuditLogs from 'reducers/AuditLogs/AuditLogsReducers';
import Dashboard from 'reducers/Dashboard/DashboardReducers';
import Reports from 'reducers/Reports/ReportReducers';
import OneDrive from 'reducers/OneDrive/OneDriveReducers';
import AIStudio from 'reducers/AIStudio/AIStudioReducers';
import { createHashHistory } from 'history';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createHashHistory()
});

export default combineReducers({
  router: routerReducer,
  layout,
  alerts,
  auth,
  navigation,
  LegalCase,
  LegalHold,
  search,
  legalCaseStats,
  credentials,
  Export,
  viewers,
  AuditLogs,
  Dashboard,
  Reports,
  OneDrive,
  AIStudio
});

export { routerMiddleware, createReduxHistory }; // Export routerMiddleware for use in the store
