const initialData = {
  containers: [],
  legalHoldNames: [],
  openFileModal: false,
  userIdentities: [],
  selectedCustodiansList: [],
  selectedExternalCustodians: [],
  allCustodiansList: [],
  allCustodiansCount: 0,
  createdLegalHoldId: '',
  currentStep: 1,
  openInternalSelectionModal: false,
  temporaryCustodiansCount: 0,
  legalHoldRecord: {},
  custodians: [],
  custodiansCount: 0,
  legalHoldStats: {}
};

export default (state = initialData, { type, payload }) => {
  if (type === 'FILE_LEGALHOLD_FORM_CONTAINERS_STARTED') {
    return {
      ...state
    };
  }
  if (type === 'FILE_LEGALHOLD_FORM_CONTAINERS_SUCCESS') {
    return {
      ...state,
      containers: payload
    };
  }
  if (type === 'FILE_LEGALHOLD_FORM_CONTAINERS_ERROR') {
    return {
      ...state,
      containers: []
    };
  }
  if (type === 'FILE_LEGALHOLD_FORM_FIND_NAMES_STARTED') {
    return {
      ...state
    };
  }
  if (type === 'FILE_LEGALHOLD_FORM_FIND_NAMES_SUCCESS') {
    return {
      ...state,
      legalHoldNames: payload
    };
  }
  if (type === 'FILE_LEGALHOLD_FORM_FIND_NAMES_ERROR') {
    return {
      ...state,
      legalHoldNames: []
    };
  }
  if (type === 'FILE_LEGALHOLD_FORM_OPEN_CONFIRM') {
    return {
      ...state,
      openFileModal: true,
      legalHoldStats: {}
    };
  }

  if (type === 'FILE_LEGALHOLD_FORM_CLOSE_CONFIRM') {
    return {
      ...state,
      openFileModal: false,
      selectedCustodiansList: [],
      selectedExternalCustodians: []
    };
  }
  if (type === 'CUSTODIAN_IDENTITIES_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      userIdentities: payload.userIdentities
    };
  }

  if (type === 'CUSTODIAN_IDENTITIES_LIST_FETCH_ERROR') {
    return {
      ...state,
      userIdentities: []
    };
  }

  if (type === 'SELECTED_CUSTODIANS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      selectedCustodiansList: payload.selectedCustodians
    };
  }

  if (type === 'SELECTED_CUSTODIANS_LIST_FETCH_ERROR') {
    return {
      ...state,
      selectedCustodiansList: []
    };
  }

  if (type === 'SELECTED_EXTERNAL_CUSTODIANS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      selectedExternalCustodians: payload.selectedExternalCustodians
    };
  }

  if (type === 'SELECTED_EXTERNAL_CUSTODIANS_LIST_FETCH_ERROR') {
    return {
      ...state,
      selectedExternalCustodians: []
    };
  }
  if (type === 'ALL_CUSTODIANS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      allCustodiansList: payload.allCustodiansList,
      allCustodiansCount: payload.allCustodiansCount
    };
  }

  if (type === 'ALL_CUSTODIANS_LIST_FETCH_ERROR') {
    return {
      ...state,
      allCustodiansList: [],
      allCustodiansCount: 0
    };
  }

  if (type === 'FILE_LEGALHOLD_FORM_CREATE_SUCCESS') {
    return {
      ...state,
      createdLegalHoldId: payload
    };
  }
  if (type === 'LEGALHOLD_FORM_INTERNAL_CUSTODIAN_SELECTION_OPEN') {
    return {
      ...state,
      openInternalSelectionModal: true
    };
  }

  if (type === 'LEGALHOLD_FORM_INTERNAL_CUSTODIAN_SELECTION_CLOSE') {
    return {
      ...state,
      openInternalSelectionModal: false,
      openCustodianModal: false,
      temporaryCustodiansCount: 0
    };
  }

  if (type === 'LEGALHOLD_FORM_SET_SELECTED_CUSTODIANS_COUNT') {
    return {
      ...state,
      temporaryCustodiansCount: payload.count
    };
  }

  if (type === 'FILE_LEGALHOLD_FORM_FIND_STARTED') {
    return {
      ...state
    };
  }
  if (type === 'FILE_LEGALHOLD_FORM_FIND_SUCCESS') {
    return {
      ...state,
      legalHoldRecord: payload
    };
  }
  if (type === 'FILE_LEGALHOLD_FORM_FIND_ERROR') {
    return {
      ...state,
      legalHoldRecord: {}
    };
  }
  if (type === 'FILE_CUSTODIANS_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      custodians: payload.custodians,
      custodiansCount: payload.custodiansCount
    };
  }

  if (type === 'FILE_CUSTODIANS_LIST_FETCH_ERROR') {
    return {
      ...state,
      custodians: [],
      custodiansCount: 0
    };
  }

  if (type === 'ONE_DRIVE_FORM_GET_STATISTICS_SUCCESS') {
    return {
      ...state,
      legalHoldStats: payload
    };
  }

  if (type === 'ONE_DRIVE_FORM_GET_STATISTICS_ERROR') {
    return {
      ...state,
      legalHoldStats: {}
    };
  }
  return state;
};
