const initialData = {
  loading: false,
  configurationData: {}
};

export default (state = initialData, { type, payload }) => {
  if (type === 'CONFIGURATION_DATA_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'CONFIGURATION_DATA_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      configurationData: payload
    };
  }

  if (type === 'CONFIGURATION_DATA_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      configurationData: {}
    };
  }

  return state;
};
