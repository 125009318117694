import React, { useState, useEffect } from 'react';
import Widget from 'components/Widget/Widget';
import AIDashboard from './AIDashboard';
import ModelManagement from './ModelManagement';
import Configuration from './Configuration';
import EmailLabeling from './EmailLabeling';
import styles from './AiStudioStyles.module.scss';
import * as signalR from '@microsoft/signalr';
import config from 'config';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { withRouter } from 'utils/withRouter';

function AiStudioPage(props) {
  const [defaultTabId, setDefaultTabId] = useState(1);
  const [connection, setConnection] = useState(null);
  const [progressData, setProgressData] = useState({});
  const [llmProgressData, setLlmProgressData] = useState({
    categorizedEmails: 0,
    emailsProcessed: 0,
    emailsSubmitted: 0,
    percentage: 0,
    uncategorizedEmails: 0
  });

  // SignalR communication with modelManagementHub

  useEffect(() => {
    console.log('Initializing SignalR connection...');

    // Initialize SignalR connection
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${config.aiStudioApi}/modelManagementHub`)
      .withAutomaticReconnect()
      .build();

    newConnection
      .start()
      .then(() => {
        console.log('SignalR Connected!');
        setConnection(newConnection);
      })
      .catch((err) => console.error('Connection Error: ', err));

    return () => {
      if (newConnection) {
        newConnection.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (connection) {
      connection.on('ReceiveProgressUpdate', (data) => {
        setProgressData(data);
        // console.log('Progress Update Received:', data);
      });

      connection.on('ReceiveLLMProgressUpdate', (data) => {
        setLlmProgressData(data);
        //console.log('ReceiveLLMProgressUpdate Received:', data);
      });

      connection.onreconnecting((error) => {
        console.warn('SignalR reconnecting...', error);
      });

      connection.onreconnected((connectionId) => {
        console.log('SignalR reconnected. Connection ID:', connectionId);
      });

      connection.onclose((error) => {
        console.error('SignalR connection closed:', error);
      });
    }

    return () => {
      if (connection) {
        connection.off('ReceiveProgressUpdate');
      }
    };
  }, [connection]);

  const changeTab = (id) => {
    setDefaultTabId(id);
  };

  const renderStepContent = (tabId) => {
    switch (tabId) {
      case 1:
        return <AIDashboard />;

      case 2:
        return <EmailLabeling llmProgressData={llmProgressData} />;
      case 3:
        return <ModelManagement progressData={progressData} />;
      case 4:
        return <Configuration />;
      default:
        return <AIDashboard />;
    }
  };
  return (
    <>
      {props?.permissions['LegalUI.AIStudio'] ? (
        <Widget>
          <div className='d-flex justify-content-between align-content-center'>
            <p className='first-headline-text ms-2'> AI Studio</p>
            <div className='d-flex justify-content-between align-content-center'>
              <p
                className={`me-3 ${defaultTabId === 1 ? styles.navActive : styles.navHeader}`}
                onClick={() => changeTab(1)}
              >
                Dashboard
              </p>
              <p
                className={`me-3 ${defaultTabId === 2 ? styles.navActive : styles.navHeader}`}
                onClick={() => changeTab(2)}
              >
                Email Labeling
              </p>
              <p
                className={`me-3 ${defaultTabId === 3 ? styles.navActive : styles.navHeader}`}
                onClick={() => changeTab(3)}
              >
                Model Management
              </p>
              <p
                className={`me-3 ${defaultTabId === 4 ? styles.navActive : styles.navHeader}`}
                onClick={() => changeTab(4)}
              >
                Configuration
              </p>
            </div>
          </div>
          <div>{renderStepContent(defaultTabId)}</div>
        </Widget>
      ) : (
        <Navigate to='/no-access' replace />
      )}
    </>
  );
}
function mapStateToProps(store) {
  return {
    permissions: store.auth.permissions
  };
}
export default withRouter(connect(mapStateToProps)(AiStudioPage));
