const initialData = {
  categoryDistribution: [],
  trainingCategoryDistribution: [],
  trainingSyntheticDistribution: [],
  emailsToBeLabeledCount: 0,
  labeledEmailsCount: 0,
  inferenceMetrics: [],
  trainedModelHistory: [],
  activeTrainedModel: {},
  emailLabelingSettings: {}
};

export default (state = initialData, { type, payload }) => {
  if (type === 'CATEGORY_DISTRIBUTION_FETCH_SUCCESS') {
    return {
      ...state,
      categoryDistribution: payload
    };
  }

  if (type === 'CATEGORY_DISTRIBUTION_FETCH_ERROR') {
    return {
      ...state,
      categoryDistribution: []
    };
  }
  if (type === 'TRAINING_CATEGORY_DISTRIBUTION_FETCH_SUCCESS') {
    return {
      ...state,
      trainingCategoryDistribution: payload
    };
  }

  if (type === 'TRAINING_CATEGORY_DISTRIBUTION_FETCH_ERROR') {
    return {
      ...state,
      trainingCategoryDistribution: []
    };
  }
  if (type === 'TRAINING_SYNTHETIC_DISTRIBUTION_FETCH_SUCCESS') {
    return {
      ...state,
      trainingSyntheticDistribution: payload
    };
  }

  if (type === 'TRAINING_SYNTHETIC_DISTRIBUTION_FETCH_ERROR') {
    return {
      ...state,
      trainingSyntheticDistribution: []
    };
  }

  if (type === 'EMAILS_TO_BE_LABELED_COUNT_FETCH_SUCCESS') {
    return {
      ...state,
      emailsToBeLabeledCount: payload
    };
  }

  if (type === 'EMAILS_TO_BE_LABELED_COUNT_FETCH_ERROR') {
    return {
      ...state,
      emailsToBeLabeledCount: 0
    };
  }

  if (type === 'LABELED_EMAILS_COUNT_FETCH_SUCCESS') {
    return {
      ...state,
      labeledEmailsCount: payload
    };
  }

  if (type === 'LABELED_EMAILS_COUNT_FETCH_ERROR') {
    return {
      ...state,
      labeledEmailsCount: 0
    };
  }

  if (type === 'INFERENCE_METRICS_FETCH_SUCCESS') {
    return {
      ...state,
      inferenceMetrics: payload
    };
  }

  if (type === 'INFERENCE_METRICS_FETCH_ERROR') {
    return {
      ...state,
      inferenceMetrics: []
    };
  }

  if (type === 'ACTIVE_TRAINED_MODEL_FETCH_SUCCESS') {
    return {
      ...state,
      activeTrainedModel: payload
    };
  }

  if (type === 'ACTIVE_TRAINED_MODEL_FETCH_ERROR') {
    return {
      ...state,
      activeTrainedModel: {}
    };
  }
  if (type === 'TRAINED_MODEL_HISTORY_FETCH_SUCCESS') {
    return {
      ...state,
      trainedModelHistory: payload
    };
  }

  if (type === 'TRAINED_MODEL_HISTORY_FETCH_ERROR') {
    return {
      ...state,
      trainedModelHistory: []
    };
  }

  if (type === 'EMAIL_LABELING_SETTINGS_FETCH_SUCCESS') {
    return {
      ...state,
      emailLabelingSettings: payload
    };
  }

  if (type === 'EMAIL_LABELING_SETTINGS_FETCH_ERROR') {
    return {
      ...state,
      emailLabelingSettings: {}
    };
  }
  return state;
};
