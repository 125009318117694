import React, { Component } from 'react';
import {
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Progress
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import styles from 'components/CRUD/LegalHold/list/LegalHoldList.module.scss';
import { emptyDataMessage, customCloseBtn } from 'actions/common';
import caretDown from 'images/icons/caret-down.svg';
import Pagination from 'react-js-pagination';
import aiStyles from './AiStudioStyles.module.scss';
import checkIcon from 'images/icons/aiStudio/check.svg';
import robotIcon from 'images/icons/aiStudio/robot.svg';
import trashIcon from 'images/icons/aiStudio/trash.svg';
import searchIcon from 'images/icons/aiStudio/search.svg';
import EmailDetails from './EmailDetails';
import { connect } from 'react-redux';
import actions from 'actions/AIStudio/EmailLabelingActions';
import Loader from 'components/Loader/Loader';
import closeIcon from 'images/icons/aiStudio/close.svg';

export class LabeledEmailsTable extends Component {
  state = {
    defaultTabsTabId: '1',
    resetModalOpen: false,
    dropdownItem: [],
    totalCount: 0,
    newRows: [],
    pageNumber: 1,
    pageSize: 10,
    sortOrder: 'subject_asc',
    selectedOption: '',
    selectedRows: [],
    openCategorizationModal: false,
    selectedCategory: '',
    searchString: '',
    hasAttachments: false,
    confidenceRed: {
      minValue: '',
      maxValue: ''
    },
    confidenceYellow: {
      minValue: '',
      maxValue: ''
    },
    confidenceGreen: {
      minValue: '',
      maxValue: ''
    },
    emailDetailsObj: {
      emailId: '',
      anonymizedBody: '',
      anonymizedSubject: '',
      body: '',
      subject: ''
    }
  };

  componentDidMount() {
    const { dispatch, configurationData } = this.props;

    dispatch(
      actions.doFetchEmails({
        sortOrder: this.state.sortOrder,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        searchString: this.state.searchString,
        hasAttachments: this.state.hasAttachments,
        categoryType: this.state.selectedCategory
      })
    ).then(() => {
      this.setState({
        newRows: this.props.labeledEmailsList,
        totalCount: this.props.labeledCount
      });
    });

    this.setState({
      confidenceRed: {
        minValue: configurationData.redMini,
        maxValue: configurationData.redMax
      },
      confidenceYellow: {
        minValue: configurationData.yellowMini,
        maxValue: configurationData.yellowMax
      },
      confidenceGreen: {
        minValue: configurationData.greenMini,
        maxValue: configurationData.greenMax
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { dispatch, configurationData } = this.props;
    if (
      prevState.sortOrder !== this.state.sortOrder ||
      prevState.pageNumber !== this.state.pageNumber ||
      prevState.pageSize !== this.state.pageSize ||
      (prevState.searchString !== this.state.searchString && this.state.searchString === '') ||
      prevState.hasAttachments !== this.state.hasAttachments ||
      prevState.selectedCategory !== this.state.selectedCategory
    ) {
      dispatch(
        actions.doFetchEmails({
          sortOrder: this.state.sortOrder,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          searchString: this.state.searchString,
          hasAttachments: this.state.hasAttachments,
          categoryType: this.state.selectedCategory
        })
      ).then(() => {
        this.setState({
          newRows: this.props.labeledEmailsList,
          totalCount: this.props.labeledCount
        });
      });
    }
    if (prevProps.configurationData !== this.props.configurationData) {
      this.setState({
        confidenceRed: {
          minValue: configurationData.redMini,
          maxValue: configurationData.redMax
        },
        confidenceYellow: {
          minValue: configurationData.yellowMini,
          maxValue: configurationData.yellowMax
        },
        confidenceGreen: {
          minValue: configurationData.greenMini,
          maxValue: configurationData.greenMax
        }
      });
    }
    if (prevProps.labeledEmailsList !== this.props.labeledEmailsList) {
      this.setState({
        newRows: this.props.labeledEmailsList,
        totalCount: this.props.labeledCount
      });
    }
  }

  changeTab(field, id) {
    this.setState({
      [field]: id
    });
  }

  actionFormatter(cell, row) {
    return (
      <p className='d-flex justify-content-center align-content-center'>
        <button
          type='button'
          className={aiStyles.secondaryButton}
          onClick={() => {
            this.props.dispatch(actions.doOpenDetailsModal());
            this.setState({
              emailDetailsObj: {
                emailId: row?.id,
                anonymizedBody: row?.anonymizedBody,
                anonymizedSubject: row?.anonymizedSubject,
                body: row?.body,
                subject: row?.subject
              }
            });
          }}
          style={{ width: '70px' }}
        >
          View
        </button>
      </p>
    );
  }

  handlePageChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber });
  };

  sortFormatter(sortField) {
    const SortEnum = {
      subject: 'subject',
      assignedCategory: 'assignedcategory',
      confidence: 'confidence',
      correctedCategory: 'correctedcategory'
    };
    return SortEnum[sortField];
  }

  handleTableChange = (type, { page, sortField, sortOrder }) => {
    if (type === 'sort') {
      this.setState({
        pageNumber: 1,
        sortOrder: this.sortFormatter(sortField).concat('_').concat(sortOrder)
      });
    }
  };

  closeModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.doCloseDetailsModal());
  };

  handleSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState({
        selectedRows: [...this.state.selectedRows, row]
      });
    } else {
      const filteredRows = this.state.selectedRows?.filter((r) => r.id !== row.id);
      this.setState({
        selectedRows: filteredRows
      });
    }
  };

  handleSelectAll = (isSelect, rows) => {
    if (isSelect) {
      this.setState({
        selectedRows: rows
      });
    } else {
      this.setState({
        selectedRows: []
      });
    }
  };

  getEmailIds = (data) => {
    let list = [];
    data?.map((item) => {
      list.push(item.id);
    });
    return list;
  };

  handleSearch = () => {
    const { dispatch } = this.props;
    this.setState({ pageNumber: 1 });
    dispatch(
      actions.doFetchEmails({
        sortOrder: this.state.sortOrder,
        pageNumber: this.state.pageNumber,
        pageSize: this.state.pageSize,
        searchString: this.state.searchString,
        hasAttachments: this.state.hasAttachments,
        categoryType: this.state.selectedCategory
      })
    ).then(() => {
      this.setState({
        newRows: this.props.labeledEmailsList,
        totalCount: this.props.labeledCount
      });
    });
  };

  handleDeleteEmails = () => {
    const { dispatch } = this.props;
    const emailIds = this.getEmailIds(this.state.selectedRows);
    dispatch(actions.doDeleteEmails(emailIds)).then(() => {
      dispatch(
        actions.doFetchEmails({
          sortOrder: this.state.sortOrder,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          searchString: this.state.searchString,
          hasAttachments: this.state.hasAttachments,
          categoryType: this.state.selectedCategory
        })
      ).then(() => {
        this.setState({
          newRows: this.props.labeledEmailsList,
          totalCount: this.props.labeledCount,
          selectedRows: []
        });
      });
    });
  };

  handleStartLlmCategorization = () => {
    const { dispatch } = this.props;
    const emailIds = this.getEmailIds(this.state.selectedRows);
    const formattedData = { selectedEmails: emailIds };

    dispatch(actions.doStartLLMCategorization(formattedData));
  };

  handleUpdateCategory = () => {
    const { dispatch } = this.props;
    const emailIds = this.getEmailIds(this.state.selectedRows);
    const category = this.state.selectedOption;
    const data = {
      selectedEmails: emailIds,
      category
    };

    dispatch(actions.doUpdateCategory(data)).then(() => {
      dispatch(
        actions.doFetchEmails({
          sortOrder: this.state.sortOrder,
          pageNumber: this.state.pageNumber,
          pageSize: this.state.pageSize,
          searchString: this.state.searchString,
          hasAttachments: this.state.hasAttachments,
          categoryType: this.state.selectedCategory
        })
      ).then(() => {
        this.setState({
          newRows: this.props.labeledEmailsList,
          totalCount: this.props.labeledCount,
          selectedRows: []
        });
      });
    });
  };

  getColor = (cell) => {
    const value = Math.floor(cell);
    if (value >= this.state.confidenceRed.minValue && value <= this.state.confidenceRed.maxValue) {
      return '#FF97AA';
    } else if (
      value >= this.state.confidenceYellow.minValue &&
      value <= this.state.confidenceYellow.maxValue
    ) {
      return '#F3BC00';
    } else if (
      value >= this.state.confidenceGreen.minValue &&
      value <= this.state.confidenceGreen.maxValue
    ) {
      return '#91C964';
    }
  };

  render() {
    const columns = [
      {
        dataField: 'subject',
        text: 'Subject',
        sort: true,
        formatter: (cell, row) => <p className={aiStyles.subjectFormatter}>{cell}</p>
      },
      {
        dataField: 'assignedCategory',
        text: 'Assigned Category',
        sort: true
      },
      {
        dataField: 'correctedCategory',
        text: 'Corrected Category',
        sort: true
      },
      {
        dataField: 'confidence',
        text: 'Confidence %',
        sort: true,
        formatter: (cell, row) => {
          const confidencePercentage = cell * 100;
          const textColor = this.getColor(confidencePercentage);
          if (cell) {
            return <p style={{ color: textColor }}>{confidencePercentage.toFixed(5)}%</p>;
          }
        }
      },
      {
        dataField: 'llmSuggestedCategory',
        text: 'LLM Suggested Category'
      },
      {
        dataField: 'llmSuggestedConfidence',
        text: 'LLM Confidence %',
        formatter: (cell, row) => {
          const confidencePercentage = cell * 100;
          const textColor = this.getColor(confidencePercentage);
          if (cell) {
            return <p style={{ color: textColor }}>{confidencePercentage.toFixed(4)}%</p>;
          }
        }
      },
      {
        dataField: 'containsAttachment',
        text: 'Contains Attachment',
        formatter: (cell, row) => <p>{cell ? 'Yes' : 'No'}</p>
      },
      {
        dataField: 'attachmentCount',
        text: 'Attachment Count'
      },
      {
        dataField: 'attachmentExtensions',
        text: 'Extensions',
        formatter: (cell, row) => {
          return (
            <p className='w-100'>
              {cell.length > 0 && (
                <p className={aiStyles.extensionsContainer}>
                  {cell?.map((item) => (
                    <span className={aiStyles.extensions}>{item}</span>
                  ))}
                </p>
              )}
            </p>
          );
        }
      },
      {
        dataField: 'id',
        text: 'Actions',
        csvExport: false,
        formatter: (cell, row) => {
          return this.actionFormatter.bind(this)(cell, row);
        },
        events: {
          onClick: (e, column, columnIndex, row) => {
            if (this.state.dropdownItem.includes(row.legalHoldId)) {
              this.setState({
                dropdownItem: []
              });
            } else {
              this.setState({
                dropdownItem: [row.legalHoldId]
              });
            }
          }
        }
      }
    ];

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.handleSelect(row, isSelect);
      },
      onSelectAll: (isSelect, rows, e) => {
        this.handleSelectAll(isSelect, rows);
      }
    };

    const categoryOptions = [
      { label: 'Select...', value: '' },
      { label: 'Business Relevant', value: 'Business Relevant' },
      { label: 'ROT', value: 'ROT' },
      { label: 'Privileged', value: 'Privileged' },
      { label: 'Sensitive', value: 'Sensitive' },
      { label: 'System Generated', value: 'System Generated' }
    ];

    const categories = [
      { label: 'All', value: '' },
      { label: 'Business Relevant', value: 'Business Relevant' },
      { label: 'ROT', value: 'ROT' },
      { label: 'Privileged', value: 'Privileged' },
      { label: 'Sensitive', value: 'Sensitive' },
      { label: 'System Generated', value: 'System Generated' }
    ];

    return (
      <div style={{ minHeight: '700px' }}>
        <div className={aiStyles.filtersContainer}>
          <p
            className='d-flex justify-content-center align-items-center me-2 pe-2'
            style={{ borderRight: '1px solid #787878', width: '700px' }}
          >
            <input
              type='search'
              placeholder={'Search emails...'}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.handleSearch();
                }
              }}
              style={{
                borderRadius: '8px',
                height: '35px'
              }}
              className={`form-control search-input ${aiStyles.searchInput}`}
              value={this.state.searchString}
              onChange={(e) => {
                this.setState({
                  searchString: e.target.value
                });
              }}
            />
            <button
              className={`me-2 ${aiStyles.secondaryButton}`}
              style={{ height: '30px' }}
              type='button'
              onClick={this.handleSearch}
            >
              <img
                title={'search'}
                alt={'search'}
                width={16}
                height={16}
                src={searchIcon}
                className='me-2'
              />
              Search
            </button>
          </p>

          <p
            className='d-flex justify-content-around align-items-center pe-2'
            style={{ borderRight: '1px solid #787878' }}
          >
            <p className='d-flex justify-content-center align-items-center mb-0 me-1'>
              Show Category
            </p>
            <select
              value={this.state.selectedCategory}
              onChange={(e) => {
                this.setState({
                  selectedCategory: e.target.value
                });
              }}
              className={`${aiStyles.secondaryButton} pe-2 me-2`}
              style={{ width: '120px' }}
              placeholder='Select...'
            >
              {categories.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div
              className='d-flex justify-content-between align-items-center mb-0 ms-2'
              style={{ width: '160px' }}
            >
              <input
                type='checkbox'
                className={aiStyles.checkBoxIcon}
                onClick={() => {
                  this.setState({
                    hasAttachments: !this.state.hasAttachments
                  });
                }}
              />
              <p className='w-100 mb-0'>Has Attachments</p>
            </div>
          </p>
          <p
            className='d-flex justify-content-between align-items-center'
            style={{
              minWidth: '700px'
            }}
          >
            <span className='ms-2 me-2 mb-0'>Apply Category</span>
            <select
              value={this.state.selectedOption}
              onChange={(e) => {
                this.setState({
                  selectedOption: e.target.value
                });
              }}
              className={`${aiStyles.secondaryButton} pe-2`}
              style={{ width: '120px' }}
              placeholder='Select...'
              disabled={this.state.selectedRows?.length === 0}
            >
              {categoryOptions.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <button
              type='button'
              className={aiStyles.secondaryButton}
              onClick={() => this.handleUpdateCategory()}
              disabled={this.state.selectedRows?.length === 0}
            >
              <img src={checkIcon} alt={'check'} className={'me-2'} />
              Apply
            </button>
            <button
              type='button'
              className={aiStyles.secondaryButton}
              onClick={() => {
                this.handleStartLlmCategorization([]);
                this.setState({
                  openCategorizationModal: true,
                  selectedRows: []
                });
              }}
              disabled={this.state.selectedRows?.length === 0}
            >
              <img src={robotIcon} alt={'robot'} className={'me-2'} />
              LLM Categorization
            </button>
            <button
              type='button'
              className={aiStyles.secondaryButton}
              onClick={() => this.handleDeleteEmails()}
              disabled={this.state.selectedRows?.length === 0}
            >
              <img src={trashIcon} alt={'trash'} className={'me-2'} />
              Delete
            </button>
          </p>
        </div>
        <Row sm={12}>
          <Col sm={12}>
            <ToolkitProvider bootstrap4 keyField='id' columns={columns} data={this.state.newRows}>
              {(props) => (
                <>
                  <div className={aiStyles.tableContainer}>
                    <BootstrapTable
                      bordered={false}
                      classes={`table-striped table-hover fs-sm ${aiStyles.emailLabeledTable}`}
                      remote={{
                        filter: false,
                        pagination: false,
                        sort: true,
                        cellEdit: false
                      }}
                      onTableChange={this.handleTableChange}
                      noDataIndication={this.props.loading ? <Loader /> : emptyDataMessage}
                      {...props.baseProps}
                      selectRow={selectRow}
                    />
                  </div>
                  <Row key={'pagination'} className='mt-3'>
                    <Col>
                      <p className={styles.totalCount}>Total: {this.state.totalCount}</p>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                      {this.state.totalCount ? (
                        <Pagination
                          totalItemsCount={this.state.totalCount}
                          onChange={this.handlePageChange}
                          activePage={this.state.pageNumber}
                          itemsCountPerPage={this.state.pageSize}
                          pageRangeDisplayed={5}
                          prevPageText={'<'}
                          nextPageText={'>'}
                          firstPageText={'<<'}
                          lastPageText={'>>'}
                          linkClassFirst={styles.paginationNext}
                          linkClassPrev={styles.paginationNext}
                          linkClassNext={styles.paginationNext}
                          linkClassLast={styles.paginationNext}
                          linkClass={styles.pageLink}
                          activeLinkClass={styles.activeLinkClass}
                        />
                      ) : null}
                      <Dropdown
                        isOpen={this.state.paginationModalOpen}
                        toggle={() =>
                          this.setState({
                            paginationModalOpen: !this.state.paginationModalOpen
                          })
                        }
                        className={styles.pageSizeDropdown}
                      >
                        <DropdownToggle className='bg-transparent text-white d-flex'>
                          <p
                            style={{ marginBottom: '3px', marginRight: '2px' }}
                            className='first-body-text'
                          >
                            {this.state.pageSize}
                          </p>
                          <img
                            src={caretDown}
                            alt={'uncollapsed'}
                            width='20px'
                            height='20px'
                            className='ms-2'
                          />
                        </DropdownToggle>
                        <DropdownMenu className='dropdown-position-fixed'>
                          {[10, 15, 25].map((item) => (
                            <DropdownItem
                              className={styles.dropdownItem}
                              key={item}
                              onClick={() => {
                                this.setState({
                                  pageSize: item,
                                  paginationModalOpen: false,
                                  pageNumber: 1
                                });
                              }}
                            >
                              {item}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>

                  {this.state.openCategorizationModal && (
                    <div className={aiStyles.llmCard}>
                      <p className={aiStyles.llmHeader}>LLM Categorization Progress</p>
                      <div className='w-100'>
                        <Progress
                          value='100'
                          style={{ height: '20px', background: ' #4A4A4A' }}
                          barStyle={{ background: '#91C964', color: '#030303' }}
                        >
                          <span className={aiStyles.copyEmailProgress}>
                            {' '}
                            {this.props.llmProgressData.percentage} %
                          </span>
                        </Progress>
                      </div>

                      <p>
                        Status:{' '}
                        {this.props.llmProgressData.percentage === 100 ? 'Finished' : 'In Progress'}
                      </p>
                      <p className={aiStyles.llmSummary}>Summary</p>
                      <p className='mb-0'>
                        <span className='me-2'>Emails Submitted:</span>
                        <span>{this.props.llmProgressData.emailsSubmitted}</span>
                      </p>
                      <p className='mb-0'>
                        <span className='me-2'>Emails Processed:</span>
                        <span>{this.props.llmProgressData.emailsProcessed}</span>
                      </p>
                      <p className='mb-0'>
                        <span className='me-2'>Categorized Emails:</span>
                        <span>{this.props.llmProgressData.categorizedEmails}</span>
                      </p>
                      <p className='mb-0'>
                        <span className='me-2'>Uncategorized Emails:</span>
                        <span>{this.props.llmProgressData.uncategorizedEmails}</span>
                      </p>
                      <p className=' w-100 d-flex justify-content-end align-items-center'>
                        <button
                          type='button'
                          className={aiStyles.secondaryButton}
                          onClick={() => {
                            this.setState({
                              openCategorizationModal: false
                            });
                          }}
                        >
                          <img src={closeIcon} className='me-1' />
                          Close
                        </button>
                      </p>
                    </div>
                  )}
                </>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
        <Modal size='lg' isOpen={this.props.openDetailsModal} className={`${aiStyles.emailModal}`}>
          <div className={aiStyles.header}>
            <p className={aiStyles.emailModalHeader}>Email Details</p>
            <p>{customCloseBtn(() => this.closeModal())}</p>
          </div>
          <EmailDetails
            emailDetailsObj={this.state.emailDetailsObj}
            dispatch={this.props.dispatch}
            filters={{
              sortOrder: this.state.sortOrder,
              pageNumber: this.state.pageNumber,
              pageSize: this.state.pageSize,
              searchString: this.state.searchString,
              hasAttachments: this.state.hasAttachments,
              categoryType: this.state.selectedCategory
            }}
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    currentTheme: store.layout.currentTheme,
    labeledEmailsList: store.AIStudio.emailLabeling.labeledEmailsList,
    labeledCount: store.AIStudio.emailLabeling.labeledCount,
    loading: store.AIStudio.emailLabeling.loading,
    configurationData: store.AIStudio.configuration.configurationData,
    openDetailsModal: store.AIStudio.emailLabeling.openDetailsModal
  };
}

export default connect(mapStateToProps)(LabeledEmailsTable);
