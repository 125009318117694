const initialData = {
  loading: false,
  notLabeledEmailsList: [],
  labeledEmailsList: [],
  notLabeledCount: 0,
  labeledCount: 0,
  openDetailsModal: false
};

export default (state = initialData, { type, payload }) => {
  if (type === 'EMAIL_LIST_FETCH_STARTED') {
    return {
      ...state,
      loading: true
    };
  }

  if (type === 'EMAIL_LIST_FETCH_SUCCESS') {
    return {
      ...state,
      loading: false,
      notLabeledEmailsList: payload.notLabeledEmails,
      labeledEmailsList: payload.labeledEmails,
      notLabeledCount: payload.notLabeledCount,
      labeledCount: payload.labeledCount
    };
  }

  if (type === 'EMAIL_LIST_FETCH_ERROR') {
    return {
      ...state,
      loading: false,
      notLabeledEmailsList: [],
      labeledEmailsList: [],
      notLabeledCount: 0,
      labeledCount: 0
    };
  }
  if (type === 'EMAIL_DETAILS_MODAL_OPEN') {
    return {
      ...state,
      openDetailsModal: true
    };
  }
  if (type === 'EMAIL_DETAILS_MODAL_CLOSE') {
    return {
      ...state,
      openDetailsModal: false
    };
  }
  return state;
};
