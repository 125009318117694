const configurationFields = {
  activeSamplingConfiguration: {
    type: 'string',
    label: 'Active Sampling Configuration'
  },

  parallelInferencePerBatch: {
    type: 'int',
    label: 'Parallel Inference Per Batch'
  },
  samplePercentage: {
    type: 'int',
    label: 'Sample Percentage'
  },
  confidenceThreshold: {
    type: 'int',
    label: 'Confidence threshold'
  },

  llmTenantId: {
    type: 'string',
    label: 'LLM Tenant ID'
  },
  llmApiUrl: {
    type: 'string',
    label: 'LLM API URL'
  },
  llmApiToken: {
    type: 'string',
    label: 'LLM API Token'
  },
  llmModelName: {
    type: 'string',
    label: 'LLM Model Name'
  },
  autoMapLLMConfidence: {
    type: 'int',
    label: 'Auto Map LLM Confidence'
  },
  samplingExclusions: {
    type: 'string',
    label: 'Sampling Exclusions'
  }
};

export default configurationFields;
