import Errors from 'components/FormItems/error/errors';
import config from 'config';
import { defaultOptions } from 'services/userService';

async function getCategoryDistribution() {
  const response = await fetch(`${config.aiStudioApi}/api/Dashboard/category-distribution`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function getTrainingCategoryDistribution() {
  const response = await fetch(
    `${config.aiStudioApi}/api/Dashboard/training-category-distribution`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getTrainingSyntheticDistribution() {
  const response = await fetch(
    `${config.aiStudioApi}/api/Dashboard/training-synthetic-distribution`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getEmailsToBeLabeledCount() {
  const response = await fetch(`${config.aiStudioApi}/api/Dashboard/emails-to-be-labeled-count`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function getLabeledEmailsCount() {
  const response = await fetch(`${config.aiStudioApi}/api/Dashboard/labeled-emails-count`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function getInferenceMetrics(metricType) {
  const response = await fetch(
    `${config.aiStudioApi}/api/Dashboard/aggregated-inference-metrics?metricType=${metricType}`,
    {
      ...defaultOptions,
      method: 'GET'
    }
  );

  const data = await response.json();
  return data;
}

async function getActiveTrainedModel() {
  const response = await fetch(`${config.aiStudioApi}/api/Dashboard/active-trained-model`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function getTrainedModelHistory() {
  const response = await fetch(`${config.aiStudioApi}/api/Dashboard/trained-models-history`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

async function getEmailLabelingSettings() {
  const response = await fetch(`${config.aiStudioApi}/api/Dashboard/email-labeling-settings`, {
    ...defaultOptions,
    method: 'GET'
  });

  const data = await response.json();
  return data;
}

const actions = {
  doGetCategoryDistribution: () => async (dispatch) => {
    try {
      dispatch({
        type: 'CATEGORY_DISTRIBUTION_FETCH_STARTED'
      });

      const response = await getCategoryDistribution();

      dispatch({
        type: 'CATEGORY_DISTRIBUTION_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'CATEGORY_DISTRIBUTION_FETCH_ERROR'
      });
    }
  },
  doGetTrainingCategoryDistribution: () => async (dispatch) => {
    try {
      dispatch({
        type: 'TRAINING_CATEGORY_DISTRIBUTION_FETCH_STARTED'
      });

      const response = await getTrainingCategoryDistribution();

      dispatch({
        type: 'TRAINING_CATEGORY_DISTRIBUTION_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'TRAINING_CATEGORY_DISTRIBUTION_FETCH_ERROR'
      });
    }
  },
  doGetTrainingSyntheticDistribution: () => async (dispatch) => {
    try {
      dispatch({
        type: 'TRAINING_SYNTHETIC_DISTRIBUTION_FETCH_STARTED'
      });

      const response = await getTrainingSyntheticDistribution();

      dispatch({
        type: 'TRAINING_SYNTHETIC_DISTRIBUTION_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'TRAINING_SYNTHETIC_DISTRIBUTION_FETCH_ERROR'
      });
    }
  },
  doGetEmailsToBeLabeledCount: () => async (dispatch) => {
    try {
      dispatch({
        type: 'EMAILS_TO_BE_LABELED_COUNT_FETCH_STARTED'
      });

      const response = await getEmailsToBeLabeledCount();

      dispatch({
        type: 'EMAILS_TO_BE_LABELED_COUNT_FETCH_SUCCESS',
        payload: response?.count
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EMAILS_TO_BE_LABELED_COUNT_FETCH_ERROR'
      });
    }
  },
  doGetLabeledEmailsCount: () => async (dispatch) => {
    try {
      dispatch({
        type: 'LABELED_EMAILS_COUNT_FETCH_STARTED'
      });

      const response = await getLabeledEmailsCount();

      dispatch({
        type: 'LABELED_EMAILS_COUNT_FETCH_SUCCESS',
        payload: response?.count
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'LABELED_EMAILS_COUNT_FETCH_ERROR'
      });
    }
  },
  doGetInferenceMetrics: (metricType) => async (dispatch) => {
    try {
      dispatch({
        type: 'INFERENCE_METRICS_FETCH_STARTED'
      });

      const response = await getInferenceMetrics(metricType);

      dispatch({
        type: 'INFERENCE_METRICS_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'INFERENCE_METRICS_FETCH_ERROR'
      });
    }
  },
  doGetActiveTrainedModel: () => async (dispatch) => {
    try {
      dispatch({
        type: 'ACTIVE_TRAINED_MODEL_FETCH_STARTED'
      });

      const response = await getActiveTrainedModel();

      dispatch({
        type: 'ACTIVE_TRAINED_MODEL_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'ACTIVE_TRAINED_MODEL_FETCH_ERROR'
      });
    }
  },
  doGetTrainedModelHistory: () => async (dispatch) => {
    try {
      dispatch({
        type: 'TRAINED_MODEL_HISTORY_FETCH_STARTED'
      });

      const response = await getTrainedModelHistory();

      dispatch({
        type: 'TRAINED_MODEL_HISTORY_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'TRAINED_MODEL_HISTORY_FETCH_ERROR'
      });
    }
  },
  doGetEmailLabelingSettings: () => async (dispatch) => {
    try {
      dispatch({
        type: 'EMAIL_LABELING_SETTINGS_FETCH_STARTED'
      });

      const response = await getEmailLabelingSettings();

      dispatch({
        type: 'EMAIL_LABELING_SETTINGS_FETCH_SUCCESS',
        payload: response
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: 'EMAIL_LABELING_SETTINGS_FETCH_ERROR'
      });
    }
  }
};
export default { ...actions };
